.vakcur-filter-wrapper {
    &--static {
        position: relative;
    }
}

.vakcur-filter-modal {
    position: fixed;
    z-index: 20;
    inset: 0;
    display: block;
    pointer-events: none;

    &__bg {
        @extend %default-transition;
        position: absolute;
        z-index: 0;
        inset: 0;
        opacity: 0;
        background: rgba(0, 0, 0, 0.2);
    }

    &.show &__bg {
        opacity: 1;
        pointer-events: initial;
    }

    &__inner {
        position: absolute;
        z-index: 1;
        bottom: 88 + 16px;
        left: 55px;
        overflow: hidden;
        box-sizing: border-box;
        display: none;
        width: 420px;
        background: var(--color-beige);
        border-radius: 14px;
    }

    &.show &__inner {
        display: block;
        pointer-events: initial;
    }

    &--static &__inner {
        top: 150px;
        bottom: auto;
        left: 90px;
    }

    &__top {
        padding: 24px 40px;
        background: var(--color-beige-3);

        span {
            @extend %type-tag;
            text-transform: uppercase;
        }

        button {
            @extend %type-tag;
            position: relative;
            text-transform: uppercase;
            display: block;
            padding: 0 0 0 20px;
            color: var(--color-black);

            &::before {
                transform: translatey(-50%) rotate(90deg);
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                content: '';
                width: 10px;
                height: 5px;
                pointer-events: none;
                background: url('/assets/images/icon-arrow-inline-down-dark.svg') no-repeat center center / cover transparent;
            }
        }
    }

    &__content-wrapper {
        padding: 30px 40px;
    }

    &__filter-list {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    &__filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        padding: 8px 0;

        &__title {
            @extend %type-tag;
            display: block;
        }

        &__content {
            @extend %default-font;
            display: flex;
            align-items: center;
            gap: 16px;
            color: var(--color-grey);

            &::after {
                display: block;
                content: '';
                width: 7px;
                height: 8px;
                background: url('/assets/images/icon-filter-arrow.svg') no-repeat center center / contain transparent;
            }
        }
    }

    &__filter-options {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__filter-option {
        display: flex;
        align-items: center;
        gap: 15px;

        &__indicator {
            width: 8px;
            height: 8px;
            border-radius: 2px;
            border: 1px solid var(--color-black);
        }

        &.active &__indicator {
            background: var(--color-mustard);
            border-color: var(--color-mustard);
        }

        &__title {
            @extend %type-body;
            display: block;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        gap: 6px;
        margin: 0 40px 24px;
        padding-top: 40px;
        border-top: 1px solid var(--color-beige-3);
    }
}