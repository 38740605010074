// Mixins
@import 'mixins/shadows';
@import 'mixins/transforms';
@import 'mixins/border-radius';

// Identity
@import 'identity/colors';
@import 'identity/typography';
@import 'identity/default-styles';

// Global
@import 'global/normalize';
@import 'global/transitions';
@import 'global/base';
@import 'global/misc';

// Elements
@import 'elements/main';
@import 'elements/btn';
@import 'elements/tag';
@import 'elements/vakcurriculum-btn';
@import 'elements/input';
@import 'elements/lpb-form';
@import 'elements/lpb-login';
@import 'elements/lpb-side';
@import 'elements/lpb-form-field';
@import 'elements/lpb-form-summary-field';
@import 'elements/lpb-dialog';
@import 'elements/lpb-form-bottom-bar';
@import 'elements/lpb-file-upload-field';
@import 'elements/lpb-vakcurriculum-form-header';
@import 'elements/top-bar';
@import 'elements/top-spacer';
@import 'elements/prikbord-topics';
@import 'elements/menskunde-topics';
@import 'elements/topic';
@import 'elements/bottom-logo';
@import 'elements/new-topic-post-box';
@import 'elements/topic-post';
@import 'elements/loading-msg';
@import 'elements/new-comment-box';
@import 'elements/notifications-list';
@import 'elements/main-menu';
@import 'elements/my-account';
@import 'elements/my-account-general';
@import 'elements/notifier';
@import 'elements/text-page';
@import 'elements/page-start';
@import 'elements/page-klas';
@import 'elements/page-vak';
@import 'elements/custom-select';
@import 'elements/arrow-btn';
@import 'elements/side-year-nav';
@import 'elements/info-modal';
@import 'elements/niveau-select-modal';
@import 'elements/feedback-module';
@import 'elements/vak-icon';
@import 'elements/card-select-modal';
@import 'elements/card';
@import 'elements/list-card';
@import 'elements/vakcur-filter-btn';
@import 'elements/vakcur-filter-modal';
@import 'elements/vakcur-history-modal';
@import 'elements/vakcur-search-modal';
@import 'elements/page-transition';
@import 'elements/topic-menu';
@import 'elements/vs-pedagogiek-topics';
@import 'elements/ontwikkelingsmoment-topics';
@import 'elements/ontwikkelingsmoment-years';
@import 'elements/word-highlight';


.slide-in {
    position: fixed;
    inset: 0;
    background: red;
    transform-origin: bottom;
}

.slide-out {
    position: fixed;
    inset: 0;
    background: blue;
    transform-origin: top;
}