.my-account {
    box-sizing: border-box;
    width: 100%;
    min-height: 100dvh;
    padding: 0 50px 50px;
    background: var(--color-white);

    &__inner {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
    }

    &__left {
        box-sizing: border-box;
        width: 30%;
        height: 100%;
        padding: 48px;
        border-radius: 14px;
        background: var(--color-beige);
    }

    &__side-title {
        @extend %type-tag;
        display: block;
        text-transform: uppercase;
        color: var(--color-grey);
    }

    &__menu {
        display: grid;
        gap: 8px;
        margin-top: 40px;

        li {
            display: block;

            a {
                @extend %default-transition;
                display: block;
                text-decoration: none;

                &.active,
                &:hover {
                    color: var(--color-blue);
                }

                &.active {
                    @extend %font-2-ultralight-italic;
                    background: url('/assets/images/icon-btn-arrow.svg') no-repeat 100% center transparent;
                    background-size: 10px auto;
                }
            }
        }
    }

    &__right {
        box-sizing: border-box;
        width: 70%;
        padding: 56px 60px;
        border-radius: 14px;
        background: var(--color-beige);
    }
}