@use 'sass:math';

.main-menu {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    box-sizing: border-box;
    display: none;
    width: 100%;
    height: 100%;
    padding: 180px 50px 180px 100px;
    background: var(--color-beige);

    &.show {
        display: block;
    }

    &__top-logo {
        display: block;
        position: absolute;
        top: 50px;
        left: 50px;
        width: 90px;
        height: auto;
    }

    &__close-btn {
        position: absolute;
        top: 38px;
        right: 38px;
        display: block;
        width: 38px;
        height: 38px;
        padding: 0;
        font-size: 0;
        line-height: 0;
        text-indent: -9999px;
        background: url('/assets/images/icon-close.svg') no-repeat center center var(--color-beige-2);
        background-size: 14px;
        appearance: none;
        outline: none;
        cursor: pointer;
        border: 0;
        border-radius: 50%;
    }

    &__inner {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        > div {
            display: flex;
            align-items: flex-end;
            width: 100%;
            gap: 150px;
        }
    }

    &__list {
        display: grid;
        gap: 15px;

        li {
            display: block;

            a {
                position: relative;
                display: inline-block;
                text-decoration: none;

                &::after {
                    @extend %medium-transition;
                    position: absolute;
                    right: 100%;
                    bottom: 0;
                    left: 0;
                    display: block;
                    content: '';
                    border-top: 2px solid var(--color-grey);
                }

                &:hover::after {
                    right: 0;
                }
            }

            &.vakcurriculum {
                color: var(--color-blue);
                a::after { border-color: var(--color-blue); }
            }

            &.menskunde {
                color: var(--color-coral);
                a::after { border-color: var(--color-coral); }
            }

            &.vs-pedagogiek {
                color: var(--color-mustard);
                a::after { border-color: var(--color-mustard); }
            }

            &.ontwikkelingsmoment {
                color: var(--color-green);
                a::after { border-color: var(--color-green); }
            }

            &.methodisch-disactisch {
                color: var(--color-purple);
                a::after { border-color: var(--color-purple); }
            }

            &.prikbord {
                color: var(--color-grey);
                a::after { border-color: var(--color-grey); }
            }
        }
    }

    &__side {
        display: grid;
        gap: 10px;

        li {
            display: block;

            a {
                @extend %type-tag;
                display: inline-block;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__bottom {
        position: absolute;
        bottom: 70px;
        left: 100px;
        display: flex;
        gap: 40px;

        li {
            display: block;
            color: var(--color-grey);
            font-size: math.div(14, $base-font-size) + 0rem;
            line-height: 1;

            a {
                display: inline-block;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    @media (min-width: 1200px) and (min-height: 850px) {
        &__top-logo {
            top: 72px;
            left: 72px;
            width: 104px;
        }
    }

    @media (min-width: 1350px) {
        padding: 200px 50px 200px 200px;

        &__inner {
            > div {
                gap: 200px;
            }
        }

        &__bottom {
            left: 200px;
        }
    }

    @media (min-height: 950px) {
        &__list {
            gap: 26px;
        }

        &__side {
            gap: 20px;
        }
    }
}