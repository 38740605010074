@use 'sass:math';

.lpb-form-field {
    &__title {
        display: block;
        text-align: center;

        &.has-info-text {
            display: flex;
            justify-content: center;

            .text {
                display: flex;
                align-items: center;
                gap: 16px;

                >span {
                    transform: translateY(1px);
                    position: relative;
                    display: block;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    color: var(--color-white);
                    background-color: var(--color-purple);
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 3px auto;

                    &:hover .lpb-form-field__info-text {
                        display: block;
                    }
                }
            }
        }
    }

    &.is-parent > &__title {
        @extend %vakcur-type-heading-2;
        color: var(--color-purple);
    }

    &.is-child > &__title {
        @extend %vakcur-type-intro;
    }

    &__info-text {
        @extend %vakcur-type-info;
        position: absolute;
        z-index: 20;
        left: -20px;
        bottom: calc(100% + 17px);
        box-sizing: border-box;
        display: none;
        padding: 15px 20px;
        width: 290px;
        color: var(--color-purple);
        text-align: left;
        background: var(--color-beige-2);
        border-radius: 10px;

        &::after {
            position: absolute;
            top: 100%;
            left: 22px;
            display: block;
            content: '';
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-top: 9px solid var(--color-beige-2);
        }

        &--side {
            transform: translateY(-50%);
            top: 50%;
            bottom: auto;
            left: calc(100% + 20px);

            &::after {
                transform: translateY(-50%) rotate(90deg);
                top: 50%;
                left: -14px;
            }
        }

        &--bottom-center {
            transform: translateX(-50%);
            top: calc(100% + 20px);
            bottom: auto;
            left: 50%;

            &::after {
                transform: translateX(-50%) rotate(180deg);
                top: -9px;
                left: 50%;
            }
        }

        &--large {
            padding: 30px 30px;
            width: 90vw;
            max-width: 650px;
        }
    }

    &__desc {
        max-width: 650px;
        margin-top: 24px;
        margin-inline: auto;
        text-align: center;
        max-inline-size: 50ch;
    }

    &__read-more-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__read-more-btn {
        margin-inline: auto;
        margin-top: 20px;
    }

    &__term-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        margin-top: 64px;

        li {
            display: block;
        }
    }

    &__input-text {
        margin-top: 64px;

        .is-child & {
            margin-top: 25px;
        }

        input {
            width: 100%;
            max-width: 530px;
            margin-inline: auto;
            text-align: left;
        }
    }

    &__input-textarea {
        margin-top: 64px;

        .is-child & {
            margin-top: 25px;
        }

        textarea {
            width: 100%;
            max-width: 530px;
            height: 160px;
            margin-inline: auto;
            text-align: left;
            resize: none;
        }
    }

    &__input-text-repeat {
        margin-top: 64px;

        .wrapper {
            position: relative;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 10px 7px;
            width: 100%;
            max-width: 530px;
            margin-inline: auto;
            padding: 15px;
            border: 1px solid var(--color-beige-3);
            border-radius: 30px;

            .suggestions {
                @include box-shadow(2px, 2px, 5px, 0px, rgba(0, 0, 0, 0.2));
                position: absolute;
                z-index: 1;
                top: calc(100% - 10px);
                left: 0;
                overflow: auto;
                box-sizing: border-box;
                display: grid;
                max-height: 217px;
                padding: 5px 0;
                background: var(--color-white);
                border-radius: 20px;

                li {
                    display: block;

                    button {
                        box-sizing: border-box;
                        display: block;
                        width: 100%;
                        padding: 15px 30px;
                        color: var(--color-black);
                        text-align: left;
                        border-bottom: 1px solid var(--color-beige-2);

                        &:hover {
                            color: var(--color-coral);
                        }
                    }
                    &:last-child button {
                        border-bottom: 0;
                    }
                }
            }
        }

        form input:focus ~ .suggestions {
            display: grid;
        }

        .form-field-tag {
            @extend %vakcur-type-buttons;
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 5px 14px 5px 15px;
            color: var(--color-white);
            background: var(--color-coral);
            border-radius: 20px;

            span {
                display: block;
            }

            button {
                position: relative;
                box-sizing: border-box;
                display: block;
                width: 6px;
                height: 6px;
                padding-bottom: 4px;
                font-size: 0;
                line-height: 0;
                text-indent: -9999px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                background-color: transparent;
                cursor: pointer;

                &::before {
                    position: absolute;
                    inset: -5px;
                    display: block;
                    content: '';
                }
            }
        }

        input {
            box-sizing: border-box;
            width: auto;
            min-width: 33ch;
            padding: 4px 10px;
            border: 0;
            text-align: left;
            border-radius: 0;
        }
    }

    &__file-upload {
        max-width: 530px;
        margin-top: 25px;
        margin-inline: auto;
    }

    &__children {
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin-top: 25px;
    }

    @media (min-height: 950px) {
        &__children {
            margin-top: 40px;
        }
    }
}