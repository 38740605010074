.lpb-side {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 275px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    &__logo {
        position: absolute;
        top: 60px;
        left: 70px;
        display: block;
        width: 54px;
        height: 54px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 54px auto;
    }
}