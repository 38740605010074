.page-klas {
    width: 100vw;
    height: 100dvh;

    &__side-nav {
        position: absolute;
    }

    &__nav-btn {
        transform: translateY(-50%);
        position: absolute;
        z-index: 15;
        top: 50%;
        display: block;
        width: 35px;
        height: 35px;
        padding: 0;
        font-size: 0;
        line-height: 0;
        text-indent: -9999px;
        background: url('/assets/images/arrow-btn.png') no-repeat center center / contain transparent;
        border-radius: 50%;

        &[disabled] {
            pointer-events: none;
            opacity: 0.5;
        }

        &--reverse {
            transform: translateY(-50%) rotate(-180deg);
            left: 8px;
        }

        &--forward {
            right: 8px;
        }
    }

    &__no-cards-msg {
        transform: translateX(-50%);
        position: absolute;
        z-index: 1;
        top: 200px;
        left: 50%;
        display: block;
        padding: 12px 25px;
        text-align: center;
        color: var(--color-black);
        background: var(--color-beige);
        border-radius: 14px;
    }

    &__bg-wrapper {
        position: absolute;
        z-index: 0;
        inset: 0;
        overflow: hidden;

        &::before,
        &::after {
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            display: block;
            content: '';
            width: 12vw;
            pointer-events: none;
        }

        &::before {
            left: 0;
            background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
        }

        &::after {
            right: 0;
            background: linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
        }
    }

    &__bg {
        transform: translate(0, -50%);
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;

        video {
            transform: scale(1.01);
            position: relative;
            z-index: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }
    }

    &__vakken {
        position: absolute;
        z-index: 1;
        inset: 0;
        display: flex;
        flex-wrap: wrap;
        opacity: 0;

        &--show {
            opacity: 1;
        }

        .vak-icon {
            @extend %default-transition;
            transform: translate(-50%, -50%);
            position: absolute;

            --spacing: 11%;
            --left-offset: 2%;

            // Line 1
            &.aardrijkskunde {
                top: 26%;
                left: calc(var(--spacing) * 1 + var(--left-offset));
            }

            &.architectuur {
                top: 29%;
                left: calc(var(--spacing) * 2 + var(--left-offset));
            }

            &.biologie {
                top: 30%;
                left: calc(var(--spacing) * 3 + var(--left-offset));
            }

            &.ckv {
                top: 29.5%;
                left: calc(var(--spacing) * 4 + var(--left-offset));
            }

            &.duits {
                top: 29.2%;
                left: calc(var(--spacing) * 5 + var(--left-offset));
            }

            &.economie {
                top: 27.5%;
                left: calc(var(--spacing) * 6 + var(--left-offset));
            }

            &.engels {
                top: 29%;
                left: calc(var(--spacing) * 7 + var(--left-offset));
            }

            &.frans {
                top: 28.5%;
                left: calc(var(--spacing) * 8 + var(--left-offset));
            }

            // Line 2
            &.geschiedenis {
                top: 54.2%;
                left: calc(var(--spacing) * 1 + var(--left-offset));
            }

            &.handvaardigheid {
                top: 55.5%;
                left: calc(var(--spacing) * 2 + var(--left-offset));
            }

            &.kunstgeschiedenis {
                top: 55.5%;
                left: calc(var(--spacing) * 3 + var(--left-offset));
            }

            &.lichamelijke-opvoeding {
                top: 54.5%;
                left: calc(var(--spacing) * 4 + var(--left-offset));
            }

            &.maatschappijleer {
                top: 53.5%;
                left: calc(var(--spacing) * 5 + var(--left-offset));
            }

            &.muziek {
                top: 52.5%;
                left: calc(var(--spacing) * 6 + var(--left-offset));
            }

            &.natuurkunde {
                top: 54%;
                left: calc(var(--spacing) * 7 + var(--left-offset));
            }

            &.nederlands {
                top: 54.5%;
                left: calc(var(--spacing) * 8 + var(--left-offset));
            }

            // Line 3
            &.scheikunde {
                top: 77.5%;
                left: calc(var(--spacing) * 1 + var(--left-offset));
            }

            &.sterrenkunde {
                top: 75.5%;
                left: calc(var(--spacing) * 2 + var(--left-offset));
            }

            &.tekenen {
                top: 79.3%;
                left: calc(var(--spacing) * 3 + var(--left-offset));
            }

            &.textiele-werkvormen {
                top: 79.3%;
                left: calc(var(--spacing) * 4 + var(--left-offset));
            }

            &.toneel-drama {
                top: 79.4%;
                left: calc(var(--spacing) * 5 + var(--left-offset));
            }

            &.wiskunde {
                top: 78.3%;
                left: calc(var(--spacing) * 6 + var(--left-offset));
            }

            &--alt-1 {
                top: 78%;
                left: calc(var(--spacing) * 7 + var(--left-offset));
            }

            &--alt-2 {
                top: 80.5%;
                left: calc(var(--spacing) * 8 + var(--left-offset));
            }
        }
    }

    // @media (orientation: landscape) {
    //     &__nav-btn {
    //         display: none;
    //     }

    //     &__bg-wrapper {

    //         &::before,
    //         &::after {
    //             display: none;
    //         }
    //     }
    // }
}