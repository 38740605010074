.custom-select {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;

    &__btn {
        white-space: nowrap;

        &.placeholder {
            color: var(--color-grey);
        }
    }

    &__options {
        position: absolute;
        top: 100%;
        right: 0;
        box-sizing: border-box;
        display: none; // Will be flex when open
        flex-direction: column;
        gap: 6px;
        min-width: 100%;
        margin-top: 20px;
        border-radius: 14px;

        li {
            transform-origin: 100% 0;
            display: flex;
            justify-content: flex-end;
            will-change: transform;

            @for $i from 1 through 50 {
                &:nth-child(#{$i}) {
                    $index: $i - 1;
                    transform: rotate(-0.2deg * ($index * $index)) translate(0.4px * ($index * $index));
                }
            }
        }
    }

    &.open &__options {
        display: flex;
    }

    &__option {
        display: inline-block;
        white-space: nowrap;
    }

    &--compact &__options {
        margin-top: 8px;
    }
}