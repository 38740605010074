.lpb-file-upload-field {
    &__loading {
        @extend %vakcur-type-buttons;
        display: block;
        padding: 7px 20px;
        text-align: center;
    }

    > form {
        display: flex;
        align-items: center;
        gap: 15px;

        input[type="file"] {
            display: none;
        }
    }

    &__icon,
    &__title,
    &__file-title {
        box-sizing: border-box;
        display: block;
        flex-shrink: 0;
        padding: 0;
        line-height: 1;
        appearance: none;
        outline: none;
        cursor: pointer;
        background: transparent;
        border: 0;
        border-radius: 0;
    }

    &__icon {
        width: 40px;
        height: 40px;
        border: 1px solid var(--color-beige-3);
        border-radius: 50%;
        background-position: 54% center;
        background-repeat: no-repeat;
        background-size: 17px auto;
    }

    &__title {
        @extend %vakcur-type-buttons;
        color: var(--color-grey);
    }

    &__file-title {
        @extend %vakcur-type-buttons;
    }

    &__btn-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-left: auto;
    }

    &__delete-btn,
    &__edit-btn {
        width: 24px;
        height: 24px;
        opacity: 0.5;
        background-repeat: no-repeat;

        &:hover {
            opacity: 1;
        }
    }

    &__delete-btn {
        display: block;
        font-size: 0;
        line-height: 0;
        text-indent: -9999px;
        background-position: center 42%;
        background-size: 17px auto;
    }

    &__edit-btn {
        background-position: center center;
        background-size: 24px auto;
    }

    &__max-upload-size {
        display: block;
        margin-top: 10px;
        margin-left: 15 + 40px;
        color: var(--color-grey);
        font-size: 0.8rem;
        font-style: italic;
    }
}