.bottom-logo {
    @extend %medium-transition;
    transform: translateX(-50%);
    transform-origin: center bottom;
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 50%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 180px;
    aspect-ratio: 1 / 0.44;
    font-size: 0;
    line-height: 0;
    user-select: none;

    &::before {
        transform: translateX(-50%);
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        content: '';
        width: 100%;
        height: 200%;
        background: var(--color-beige-2);
        border-radius: 50%;
    }

    &__logo {
        transform: translateY(4px);
        position: relative;
        z-index: 1;
        width: 90px;
        margin: auto;
        font-size: 0;
        line-height: 0;

        img {
            width: 100%;
            height: auto;
        }
    }

    &.hide {
        transform: translate(-50%, 100%) scale(0.5);
    }

    @media (min-width: 1300px) and (min-height: 850px) {
        width: 245px;
        aspect-ratio: 1 / 0.5;

        &__logo {
            width: 125px;
        }
    }
}