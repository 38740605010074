@use 'sass:math';

.niveau-select-modal {
    position: fixed;
    z-index: 20;
    inset: 0;
    overflow: auto;
    display: none;
    padding: 50px 0;
    background: rgba(0, 0, 0, 0.6);

    &.open {
        display: flex;
    }

    &__close-btn {
        position: absolute;
        top: -(44 + 14px);
        right: 0;
        display: block;
        width: 44px;
        height: 44px;
        font-size: 0;
        line-height: 0;
        text-indent: -9999px;
        appearance: none;
        cursor: pointer;
        border-radius: 50%;
        outline: none;
        background: url('/assets/images/button-close.png') no-repeat center center / cover transparent;
    }

    &__inner-wrapper {
        position: relative;
        box-sizing: border-box;
        width: 90%;
        max-width: 415px;
        margin: auto;
        padding: 14px;
        background: url('/assets/images/info-modal-bg.jpg') no-repeat center center / cover var(--color-beige);
        border-radius: 14px;

        &::before,
        &::after {
            bottom: 25px;
        }

        &::before {
            transform: rotate(-90deg);
            left: 25px;
        }

        &::after {
            transform: rotate(180deg);
            right: 25px;
        }
    }

    &__inner {
        position: relative;
        box-sizing: border-box;
        border: 1px solid var(--color-black);
        border-radius: 8px;
        padding: 75px 50px;
        text-align: center;

        &::before,
        &::after {
            top: 11px;
        }

        &::before {
            left: 11px;
        }

        &::after {
            transform: rotate(90deg);
            right: 11px;
        }
    }

    &__inner-wrapper,
    &__inner {
        &::before,
        &::after {
            position: absolute;
            display: block;
            content: '';
            width: 20px;
            height: 20px;
            background: url('/assets/images/info-modal-edges.svg') no-repeat 0 0 / cover transparent;
            pointer-events: none;
        }
    }

    &__title {
        @extend %type-tag;
        display: block;
        max-width: 380px;
        margin-inline: auto;
    }

    &__options {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
        margin-top: 50px;
    }
}