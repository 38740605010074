.lpb-form {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__top-spacer {
        position: relative;
        width: 90%;
        max-width: 1200px;
        height: 160px;
        margin-inline: auto;
    }

    &__inner {
        width: 90%;
        max-width: 1440px;
        margin-inline: auto;
    }

    &__save-status-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 45px;
    }

    &__save-status-btn {
        pointer-events: none;
        display: none;

        &.show {
            display: block;
        }
    }

    &__back-btn {
        position: relative;
        display: block;
        width: 30px;
        height: 22px;
        margin-left: 54 + 65px;
        font-size: 0;
        line-height: 0;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 30px 22px;

        &::before {
            position: absolute;
            inset: -5px;
            display: block;
            content: '';
        }
    }

    &__title {
        @extend %vakcur-type-heading-2;
        display: block;
        margin-bottom: 55px;
        color: var(--color-purple);

        &.has-info-text {
            .text {
                display: flex;
                align-items: center;
                gap: 16px;

                >span {
                    transform: translateY(1px);
                    position: relative;
                    display: block;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    color: var(--color-white);
                    background-color: var(--color-purple);
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 3px auto;

                    &:hover .lpb-form-field__info-text {
                        display: block;
                    }
                }
            }
        }
    }

    &__card-tabs {
        display: flex;
        gap: 24px;
        margin-bottom: 30px;
        border-bottom: 1px solid var(--color-beige-3);

        .tab {
            @extend %vakcur-type-intro;
            display: block;
            padding: 0 0 22px;
            appearance: none;
            outline: none;
            border: 0;
            border-bottom: 2px solid transparent;
            color: var(--color-grey);
            cursor: pointer;
            background: transparent;

            &.active,
            &:hover {
                color: var(--color-mustard);
                border-color: var(--color-mustard);
            }
        }
    }

    &__cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        .vakcur-card {
            @extend %default-transition;
            position: relative;
            box-sizing: border-box;
            width: 100%;
            padding: 26px;
            cursor: pointer;
            aspect-ratio: 1 / 1;
            border: 1px solid var(--color-beige-3);
            border-radius: 30px;

            &--new {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 30px;
            }

            &__title {
                @extend %vakcur-type-heading-3;
                display: block;
                color: var(--color-purple);
            }

            &__tags {
                display: flex;
                flex-wrap: wrap;
                gap: 4px;
                margin-top: 10px;
            }

            &__tag {
                @extend %vakcur-type-tags;
                display: block;
                padding: 5px 10px;
                color: var(--color-white);
                line-height: 1.27;
                background: var(--color-coral);
                border-radius: 15px;
            }

            &__delete-btn {
                position: absolute;
                z-index: 1;
                right: 46px;
                bottom: 15px;
                display: block;
                width: 24px;
                height: 24px;
                font-size: 0;
                line-height: 0;
                text-indent: -9999px;
                opacity: 0.5;
                background-position: center 42%;
                background-repeat: no-repeat;
                background-size: 17px auto;

                &:hover {
                    opacity: 1;
                }
            }

            &__edit-btn {
                position: absolute;
                right: 15px;
                bottom: 15px;
                width: 24px;
                height: 24px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 24px auto;
            }
        }
    }

    &__topics {
        transform: translateY(-50%);
        position: absolute;
        inset: 50% 80px auto 80px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    &__topic {
        @extend %default-transition;
        display: block;
        filter: blur(3px);
        opacity: 0.4;

        &--active {
            filter: blur(0px);
            opacity: 1;
        }

        button {
            @extend %vakcur-type-heading-1;
            display: block;
            padding: 0;
            color: var(--color-purple);
            background: transparent;
            appearance: none;
            outline: none;
            border: 0;
            cursor: pointer;
        }
    }

    &__field-wrapper {
        margin-top: 40px;
    }

    &__nav-btn-wrapper {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin-top: 50px;
    }

    &__loading {
        @extend %vakcur-type-heading-2;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        max-width: 600px;
        color: var(--color-purple);
        text-align: center;

        &--static {
            transform: translate(0, 0);
            position: static;
            width: auto;
            max-width: 100%;
            text-align: left;
        }
    }

    @media (min-width: 900px) {
        &__cards {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media (min-width: 1300px) {
        &__cards {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    @media (min-width: 1500px) {
        &__cards {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    // Height queries
    @media (min-height: 950px) {
        &__top-spacer {
            height: 220px;
        }

        &__field-wrapper {
            margin-top: 100px;
        }

        &__topics {
            gap: 56px;
        }

        &__nav-btn-wrapper {
            margin-top: 60px;
        }
    }
}