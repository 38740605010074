.loading-msg {
    @extend %default-font-bold;
    position: absolute;
    inset: 0;
    z-index: 18;
    font-size: 2rem;
    color: var(--color-purple);
    text-align: center;
    line-height: 100dvh;
    background: var(--color-beige);
}