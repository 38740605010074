.lpb-dialog {
    position: fixed;
    z-index: 15;
    inset: 0;
    display: none;
    padding: 50px 0;
    background: rgba(255, 255, 255, 0.7);

    &.show {
        display: flex;
    }

    &__inner {
        box-sizing: border-box;
        width: 90%;
        max-width: 475px;
        margin: auto;
        padding: 70px 90px 70px;
        background: var(--color-beige-2);
        border-radius: 10px;
        text-align: center;
    }

    &__title {
        @extend %vakcur-type-heading-3;
        display: block;
    }

    &__desc {
        @extend %vakcur-type-intro;
        margin-top: 12px;
        color: var(--color-grey);
    }

    &__btn-wrapper {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin-top: 40px;
    }

    &__form {
        margin-top: 25px;

        .input-wrapper {
            display: flex;
            justify-content: center;

            input {
                width: 100%;
            }
        }
    }

    &__loader {
        margin-top: 25px;
    }
}