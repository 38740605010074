.input {
    @extend %vakcur-type-buttons;
    @include border-radius-single(0);
    display: block;
    box-sizing: border-box;
    padding: 11px 20px;
    font-size: 1rem;
    color: var(--color-main-text);
    text-align: center;
    outline: none;
    border: 1px solid var(--color-beige-3);
    border-radius: 30px;
    appearance: none;
    background: transparent;

    &::-webkit-input-placeholder {
        color: var(--color-grey);
        opacity: 1;
    }

    &:-moz-placeholder {
        color: var(--color-grey);
        opacity: 1;
    }

    &::-moz-placeholder {
        color: var(--color-grey);
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color: var(--color-grey);
        opacity: 1;
    }

    &--visibility-spacing {
        padding-right: calc(20px + 33px + 15px);
        padding-left: calc(20px + 33px + 15px);
    }

    &--alt {
        border-radius: 14px;
    }
}