.new-topic-post-box {
    position: relative;
    max-width: 100ch;

    &--loading {
        pointer-events: none;

        &::before {
            transform: translate(-50%, -50%);
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            display: block;
            content: '';
            width: 35px;
            height: 35px;
            background: url('/assets/images/icon-loading.svg') no-repeat center center transparent;
            background-size: 85%;
            opacity: 1;
            animation-name: loading-animation;
            animation-duration: 1.8s;
            animation-iteration-count: infinite;
        }
    }

    > form {
        padding: 20px;
        background: var(--color-white);
        border-radius: 8px;

        .topic-post--pinned & {
            background: var(--color-beige-2);
        }
    }

    &--loading > form {
        opacity: 0.4;
    }

    &__top {
        display: flex;
        gap: 18px;
    }

    &__avatar {
        @extend %bg-image-cover-centered;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border: 1px solid var(--color-white);
        border-radius: 50%;
    }

    &__input-wrapper {
        width: 100%;

        > * + * {
            margin-top: 10px;
        }

        input,
        textarea {
            box-sizing: border-box;
            border: 1px solid var(--color-beige-3);
            border-radius: 30px;
            background: var(--color-white);
        }

        input {
            padding: 22px 19px;
        }

        textarea {
            min-height: 6rem;
            padding: 12px 19px 15px;
        }

        input[type=file] {
            display: none;
        }
    }

    &__input {
        box-sizing: border-box;
        width: 100%;
        height: 2rem;
        padding: 0;
        border: 0;
        border-radius: 0;
        text-align: left;
        font-size: 1rem;
        resize: none;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 20px;
        margin-top: 25px;
    }

    &__media-options {
        display: flex;
        gap: 6px;
        align-items: center;
        width: 100%;
        margin-left: 40 + 20px;

        &--no-margin {
            margin-left: 0;
        }

        > button {
            @extend %default-transition;
            position: relative;
            display: block;
            width: 40px;
            height: 40px;
            padding: 0;
            font-size: 0;
            line-height: 0;
            text-indent: -9999px;
            appearance: none;
            outline: none;
            cursor: pointer;
            border: 0;
            border-radius: 50%;
            background-color: var(--color-beige-3);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 17px auto;

            &:hover {
                background-color: var(--color-beige-2);
            }

            &::before {
                transform: translateX(-50%);
                position: absolute;
                bottom: calc(100% + 7px);
                left: 50%;
                display: none;
                padding: 12px 18px;
                color: var(--color-black);
                text-indent: 0;
                font-size: 0.9rem;
                line-height: 1;
                white-space: nowrap;
                background: var(--color-beige-2);
                border-radius: 14px;
            }

            &:hover::before {
                display: block;
            }
        }

        &__image {
            background-image: url('/assets/images/icon-upload-img.svg');

            &::before {
                content: 'Foto toevoegen';
            }
        }

        &__video {
            background-image: url('/assets/images/icon-upload-video.svg');

            &::before {
                content: 'Video toevoegen';
            }
        }
    }

    &__file-preview {
        font-size: 0;
        line-height: 0;
    }

    &__image-preview {
        position: relative;
        display: inline-block;
        font-size: 0;
        line-height: 0;

        button {
            @extend %default-font-bold;
            @include box-shadow(0, 0, 7px, 0, rgba(0, 0, 0, 0.3));
            position: absolute;
            top: 10px;
            right: 10px;
            display: block;
            width: 25px;
            height: 25px;
            padding: 0;
            font-size: 0;
            line-height: 0;
            text-indent: -9999px;
            appearance: none;
            outline: none;
            border: 0;
            border-radius: 50%;
            cursor: pointer;
            background: url('/assets/images/icon-delete-btn.svg') no-repeat center center white;
            background-size: 13px auto;
        }

        img {
            max-width: 250px;
            max-height: 230px;
            width: auto;
            height: auto;
        }
    }

    &__video-url-wrapper {
        width: 100%;

        .inputs {
            transform: translateY(4px);
            display: flex;
            gap: 4px;
            align-items: center;
        }

        input {
            width: 100%;
        }

        button {
            display: block;
            flex-shrink: 0;
            width: 35px;
            height: 35px;
            padding: 0;
            appearance: none;
            font-size: 0;
            line-height: 0;
            text-indent: -9999px;
            border: 0;
            border-radius: 0;
            cursor: pointer;
            background: url('/assets/images/icon-delete-tag-dark.svg') no-repeat center center transparent;
            background-size: 10px auto;
        }
    }

    &__video-preview {
        box-sizing: border-box;
        margin-top: 20px;
        padding: 15px;
        border-radius: 14px;
        background: var(--color-beige-2);

        iframe {
            width: 100%;
            height: auto;
            aspect-ratio: 16 / 9;
        }
    }

    &__bottom-btn-wrapper {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    @keyframes loading-animation {
        from {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        
        to {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
}