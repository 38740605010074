html,
body {
    height: 100%;
}

html {
    font-size: $base-font-size + -2px;

    @media (min-width: 1400px) and (min-height: 850px) {
        font-size: $base-font-size + 0px;
    }
}

body {
    @extend %default-font;
    color: var(--color-main-text);
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-beige);
}

a {
    color: inherit;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

strong {
    @extend %default-font-bold;
}