@use 'sass:math';

.side-year-nav {
    position: fixed;
    z-index: 2;
    top: 180px;
    bottom: 180px;
    left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 60px;

    &__btn {
        display: block;
        text-align: center;
        border-radius: 50%;
        background: blue;
        appearance: none;
        outline: none;
        border: 0;
        user-select: none;

        &[disabled] {
            opacity: 0;
            pointer-events: none;
        }

        &--next,
        &--prev {
            @extend %font-2-ultralight;
            width: 36px;
            height: 36px;
            color: var(--color-white);
            font-size: math.div(20, $base-font-size) + 0rem;
            border: 1px solid var(--color-beige);
            line-height: 34px;
            cursor: pointer;
            background: rgba(var(--color-beige-rgb), 0.3);
        }

        &--next {
            top: 0;
        }

        &--current {
            @extend %font-2;
            position: relative;
            width: 60px;
            height: 60px;
            color: var(--color-mustard);
            font-size: math.div(30, $base-font-size) + 0rem;
            line-height: 62px;
            background: url('/assets/images/button-clean-light.png') no-repeat center center / cover transparent;
        }

        &--prev {
            bottom: 0;
        }
    }

    &__spacer {
        width: 1px;
        height: 50%;
        background: url('/assets/images/side-year-nav-spacer.svg') center 0 transparent;
        opacity: 0;

        &--visible {
            opacity: 1;
        }
    }

    &__current {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
        margin: 5px 0;

        &__up,
        &__down {
            width: 25px;
            height: 25px;
            font-size: 0;
            left: 0;
            text-indent: -999px;
            background: url('/assets/images/side-year-nav-up-btn.svg') no-repeat center center transparent;
            background-size: 25px auto;

            &[disabled] {
                opacity: 0;
                pointer-events: none;
            }
        }

        &__up {

        }

        &__down {
            transform: rotate(180deg);
        }
    }

    &__year-info-btn {
        @extend %font-2;
        position: absolute;
        top: -1px;
        right: -8px;
        display: block;
        width: 25px;
        height: 25px;
        appearance: none;
        outline: none;
        cursor: pointer;
        font-size: math.div(13, $base-font-size) + 0rem;
        line-height: 24px;
        color: var(--color-mustard);
        background: var(--color-beige);
        border-radius: 50%;
    }
}