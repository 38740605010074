.text-page {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 0 50px 50px;
    background: var(--color-white);

    &__inner {
        overflow: hidden;
        flex-grow: 1;
        display: flex;
        gap: 60px;
        border-radius: 14px;
        background: var(--color-beige);
    }

    &__img {
        @extend %bg-image-cover-centered;
        width: 43%;
    }

    &__content-wrapper {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: center;
        width: 47%;
        padding: 80px 50px 80px 0;
    }

    &__title {
        display: block;
        max-width: 730px;
    }

    &__content {
        max-width: 730px;

        p,
        ul,
        ol {
            color: var(--color-grey);
        }
    }

    @media (min-width: 1300px) {
        &__inner {
            gap: 120px;
        }

        &__content-wrapper {
            gap: 60px;
        }
    }
}