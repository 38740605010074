@use 'sass:math';

.lpb-login {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 390px;
    height: 100%;
    margin-inline: auto;
    padding: 80px 0;

    &__logo {
        width: 258px;
        height: auto;
        margin-bottom: 80px;
    }

    &__title {
        @extend %vakcur-type-heading-2;
        display: block;
        margin-bottom: 50px;
        color: var(--color-purple);
    }

    &__form {
        @extend %default-transition;
        width: 100%;

        &[disabled] {
            opacity: 0.3;
        }
    }

    &__form-row {
        margin-bottom: 25px;
        text-align: center;

        label {
            display: block;
            margin-bottom: 10px;
            text-transform: uppercase;
            color: var(--color-grey);
            font-size: math.div(14, $base-font-size) + 0rem;
            line-height: math.div(27, $base-font-size) + 0rem;
            letter-spacing: 1px;
        }

        .input-wrapper {
            position: relative;
        }

        input {
            width: 100%;
        }

        .password-visibility-trigger {
            transform: translateY(-50%);
            position: absolute;
            top: 50%;
            right: 10px;
            width: 48px;
            height: 33px;
            background-position: 52% center;
            background-repeat: no-repeat;
            background-size: 21px auto;
            border-left: 1px solid var(--color-beige-3);
        }
    }

    &__response-msg {
        text-align: center;

        &.error {
            color: red;
        }
    }

    &__btn-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__login-btn {
        margin-top: 80px;
        margin-inline: auto;
    }

    &__forgot-password {
        display: inline-block;
        margin-top: 24px;
        margin-inline: auto;
        color: var(--color-grey);
        font-size: math.div(14, $base-font-size) + 0rem;
        line-height: math.div(27, $base-font-size) + 0rem;
    }
}