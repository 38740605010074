@use 'sass:math';

.my-account-general {
    position: relative;

    &__title {
        display: block;
    }

    &__inner {
        margin-top: 30px;
    }

    &__avatar-wrapper {
        display: flex;
        align-items: center;
        gap: 45px;

        &__avatar {
            @extend %bg-image-cover-centered;
            width: 110px;
            height: 110px;
            border-radius: 50%;
        }

        &__input-wrapper {
            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }

            > form {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 14px;
            }

            .desc {
                display: block;
                max-width: 220px;
                font-size: math.div(14, $base-font-size) + 0rem;
                line-height: math.div(22, $base-font-size) + 0rem;
                letter-spacing: 0.4px;
                color: var(--color-grey);
            }

            input {
                display: none;
            }
        }
    }

    &__form-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        margin-top: 50px;

        .input-wrapper {
            label {
                @extend %type-tag;
                display: block;
            }
    
            input {
                width: 100%;
                margin-top: 10px;
                text-align: left;
            }
        }
    }
};