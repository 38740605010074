.vakcur-history-modal-trigger {
    position: fixed;
    right: 55px;
    bottom: 40px;
    z-index: 90;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 0;
    background: url('/assets/images/button-clean-light.png') no-repeat center center / contain transparent;
    appearance: none;
    outline: none;
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    text-indent: -9999px;

    &.open {
        z-index: 110;
    }

    &::before {
        position: absolute;
        inset: 0;
        display: block;
        content: '';
        background: url('/assets/images/icon-history.svg') no-repeat 54% center transparent;
        background-size: 40%;
    }

    &::after {
        @extend %type-body;
        @extend %default-transition;
        transform: translateX(15px) translateY(-50%) scale(0.9);
        position: absolute;
        z-index: -1;
        top: 50%;
        right: 100%;
        display: block;
        content: 'Geschiedenis';
        margin-right: 10px;
        padding: 6px 14px;
        text-indent: 0;
        opacity: 0;
        background: var(--color-beige-2);
        border-radius: 14px;
        pointer-events: none;
    }

    &.open {
        background-image: url('/assets/images/button-clean.png');

        &::before {
            background-image: url('/assets/images/icon-history-light.svg');
        }

        &::after {
            color: var(--color-white);
            background-color: var(--color-mustard);
        }
    }

    &.open,
    &:hover {
        &::after {
            transform: translateX(0) translateY(-50%) scale(1);
            opacity: 1;
            pointer-events: initial;
        }
    }
}

.vakcur-history-modal {
    @extend %default-transition;
    position: fixed;
    z-index: 100;
    inset: 0;
    pointer-events: none;

    &__bg {
        @extend %default-transition;
        position: absolute;
        z-index: 0;
        inset: 0;
        opacity: 0;
        background: rgba(0, 0, 0, 0.2);
    }

    &.show &__bg {
        opacity: 1;
        pointer-events: initial;
    }

    &__inner {
        position: absolute;
        z-index: 1;
        right: 30px;
        bottom: 88 + 16px;
        overflow: hidden;
        box-sizing: border-box;
        display: none;
        width: 80vw;
        max-width: 675px;
        background: var(--color-beige);
        border-radius: 14px;
    }

    &.show &__inner {
        display: block;
        pointer-events: initial;
    }

    &__top {
        padding: 24px 40px;
        background: var(--color-beige-3);

        span {
            @extend %type-tag;
            text-transform: uppercase;
        }
    }

    &__content-wrapper {
        overflow: auto;
        display: flex;
        flex-direction: column;
        max-height: 70vh;
        padding: 10px;
        gap: 8px;
    }

    @media (min-height: 900px) {
        &__content-wrapper {
            max-height: 650px;
        }
    }
}