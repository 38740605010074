.prikbord-topics {
    position: relative;
    overflow: hidden;
    width: 100dvw;
    height: 100dvh;
    background-color: black;

    &__clouds {
        transform: translateX(-50%);
        position: absolute;
        z-index: 1;
        top: 0;
        left: 50%;
        width: 100%;
        height: 100%;

        .prikbord-topics__cloud {
            opacity: 0;
        }

        &.visible .prikbord-topics__cloud {
            opacity: 1;
        }
    }

    &__cloud {
        transform-origin: center top;
        position: absolute;
        top: 0;
        left: 0;
        width: 30%;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 100% auto;
        aspect-ratio: 512 / 1280;
        pointer-events: none;
        opacity: 0.2!important;

        &:first-child,
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(7) {
            opacity: 1!important;
        }

        &::before {
            @extend %medium-transition;
            position: absolute;
            z-index: 0;
            inset: 0;
            display: block;
            content: '';
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: 100% auto;
            pointer-events: none;
            opacity: 0;
        }

        &.active {
            &::before {
                opacity: 1;
            }
        }

        > a {
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 50%;
            display: block;
            height: auto;
            padding: 0;
            font-size: 0;
            line-height: 0;
            text-indent: -9999px;
            text-decoration: none;
            border-radius: 50%;
            // pointer-events: initial; // Should be enabled later on
            background-color: transparent;
        }

        &:first-child > a,
        &:nth-child(4) > a,
        &:nth-child(5) > a,
        &:nth-child(7) > a {
            pointer-events: initial;
        }

        &.topic-1,
        &.topic-5,
        &.topic-9 {
            background-image: url('/assets/images/clouds/1.png');

            &::before {
                background-image: url('/assets/images/clouds/1-glow.png');
            }
        }

        &.topic-2,
        &.topic-6,
        &.topic-10 {
            background-image: url('/assets/images/clouds/2.png');

            &::before {
                background-image: url('/assets/images/clouds/2-glow.png');
            }
        }

        &.topic-3,
        &.topic-7,
        &.topic-11 {
            background-image: url('/assets/images/clouds/3.png');

            &::before {
                background-image: url('/assets/images/clouds/3-glow.png');
            }
        }

        &.topic-4,
        &.topic-8,
        &.topic-12 {
            background-image: url('/assets/images/clouds/4.png');

            &::before {
                background-image: url('/assets/images/clouds/4-glow.png');
            }
        }



        &.topic-1 {
            transform: scale(1.2) rotate(3deg);
            top: -3%;
            left: -5%;
            height: 41vh;

            > a {
                transform: translate(-50%, -39%);
                width: 76%;
                aspect-ratio: 1 / 0.58;
            }
        }

        &.topic-2 {
            transform: scale(0.6) rotate(2deg);
            filter: blur(0.2px);
            z-index: 0;
            top: 10%;
            left: 16%;
            height: 60vh;

            > a {
                transform: translate(-50%, -37%);
                width: 75%;
                aspect-ratio: 1 / 0.58;
            }
        }

        &.topic-3 {
            transform: scale(1);
            top: -3%;
            left: 36%;
            height: 38vh;

            > a {
                transform: translate(-47%, -52%);
                width: 72%;
                aspect-ratio: 1 / 0.55;
            }
        }

        &.topic-4 {
            transform: scale(0.6);
            filter: blur(0.2px);
            top: 20%;
            left: 69%;
            height: 70vh;

            > a {
                transform: translate(-50%, -40%);
                width: 73%;
                aspect-ratio: 1 / 0.6;
            }
        }

        &.topic-5 {
            transform: scale(1);
            z-index: 1;
            top: 1.5%;
            left: 37%;
            height: 70vh;

            > a {
                transform: translate(-50%, -39%);
                width: 77%;
                aspect-ratio: 1 / 0.58;
            }
        }

        &.topic-6 {
            transform: scale(0.8) rotate(2deg);
            filter: blur(0.2px);
            top: 7%;
            left: 0;
            height: 72vh;

            > a {
                transform: translate(-50%, -35%);
                width: 78%;
                aspect-ratio: 1 / 0.58;
            }
        }

        &.topic-7 {
            transform: scale(1.4) rotate(-3deg);
            filter: blur(0.5px);
            top: -7%;
            left: 68%;
            height: 35vh;

            > a {
                transform: translate(-47%, -53%);
                width: 73%;
                aspect-ratio: 1 / 0.53;
            }
        }

        &.topic-8 {
            transform: scale(0.6) rotate(-2deg);
            filter: blur(0.2px);
            top: 10%;
            left: 58%;
            height: 63vh;

            > a {
                transform: translate(-50%, -34%);
                width: 80%;
                aspect-ratio: 1 / 0.58;
            }
        }

        &.topic-9 {
            transform: scale(0.6) rotate(-2deg);
            top: 6%;
            right: -10%;
            left: auto;
            height: 63vh;

            > a {
                transform: translate(-50%, -34%);
                width: 80%;
                aspect-ratio: 1 / 0.58;
            }
        }
    }

    &__hover-title {
        @extend %font-2;
        transform: translateX(-50%);
        position: absolute;
        z-index: 2;
        top: 34%;
        left: 50%;
        display: block;
        color: var(--color-beige-2);
        font-size: 6.4vw;
        text-transform: uppercase;
        white-space: nowrap;
        pointer-events: none;
    }

    &__bg-wrapper {
        position: absolute;
        z-index: 0;
        inset: 0;

        video {
            transform: translateX(-50%);
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 100%;
            height: 100%;
        }
    }

    @media (orientation: portrait) {
        &__cloud {
            &.topic-1 {
                top: -15%;
            }

            &.topic-2 {
                top: 2%;
            }

            &.topic-3 {
                top: -11%;
            }

            &.topic-4 {
                top: 10%;
            }

            &.topic-5 {
                top: -12%;
            }

            &.topic-6 {
                top: -5%;
            }

            &.topic-7 {
                top: -15%;
            }

            &.topic-8 {
                top: 2%;
            }

            &.topic-9 {
                top: -2%;
            }
        }
    }
}
