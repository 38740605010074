@use 'sass:math';

.notifications-list {
    position: relative;
    overflow: auto;
    box-sizing: border-box;
    display: none;
    width: 330px;
    max-height: 360px;
    background: var(--color-beige);
    border-radius: 14px;
    filter: drop-shadow(0px 4px 14px rgba(231, 226, 217, 0.41));

    &.show {
        display: block;
    }

    &::before {
        transform: rotate(45deg);
        position: absolute;
        z-index: -1;
        top: -5px;
        right: 38px;
        display: block;
        content: '';
        width: 13px;
        height: 13px;
        background: #e3ded4;
        border-radius: 2px;
    }

    &__no-notifications {
        padding: 5px 22px;
    }

    li {
        display: block;

        &:first-child {
            margin-bottom: 12px;
        }

        &:last-child {
            margin-bottom: 12px;
        }

        button {
            @extend %default-font;
            box-sizing: border-box;
            display: flex;
            gap: 20px;
            width: 100%;
            padding: 10px 22px;
            appearance: none;
            outline: none;
            cursor: pointer;
            text-align: left;
            font-size: math.div(16, $base-font-size) + 0rem;
            line-height: math.div(22, $base-font-size) + 0rem;
            border: 0;
            border-radius: 0;
            background: transparent;

            .icon {
                @extend %bg-image-cover-centered;
                flex-shrink: 0;
                width: 24px;
                height: 24px;
                background-color: var(--color-beige-3);
                border-radius: 50%;

                &.avatar {
                    @extend %bg-image-cover-centered;
                }
            }

            .text {
                width: 100%;

                > span {
                    @extend %default-font-bold;
                    color: var(--color-mustard);
                }
            }

            .read-indicator {
                flex-shrink: 0;
                width: 6px;
                height: 6px;
                margin-top: 9px;
                margin-bottom: auto;
                border-radius: 50%;

                &.unread {
                    background: var(--color-coral);
                }
            }

            &:hover {
                background: var(--color-beige-2);
            }
        }

        &.top button {
            @extend %default-transition;
            @extend %type-tag;
            box-sizing: border-box;
            width: 100%;
            padding: 16px 22px 14px;
            background: url('/assets/images/icon-btn-arrow.svg') no-repeat calc(100% - 20px) center #e3ded4;
            border-radius: 14px 14px 0 0;

            &:hover {
                background-position: calc(100% - 23px) center;
            }
        }
    }
}