.ontwikkelingsmoment-topics {
    position: fixed;
    inset: 0;
    overflow: hidden;
    width: 100dvw;
    height: 100dvh;
    background-color: black;

    &__targets {
        transform: translate(-50%, -50%);
        position: fixed;
        z-index: 2;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        opacity: 0;

        &.is-ready {
            opacity: 1;
        }
    }

    &__target {
        transform: translate(-50%, -50%) rotate(2deg);
        position: absolute;
        display: block;
        appearance: none;
        outline: none;
        border: 0;
        border-radius: 50%;
        opacity: 1;
        cursor: pointer;

        .year {
            --size: clamp(30px, 3vw, 45px);

            @extend %default-transition;
            @extend %font-2;
            transform: translateY(-25%);
            position: absolute;
            top: 20%;
            left: 25px;
            display: block;
            width: var(--size);
            aspect-ratio: 1;
            color: var(--color-mustard);
            font-size: 1.3rem;
            text-align: center;
            line-height: var(--size);
            background: url('/assets/images/button-clean-light.png') no-repeat center center / contain transparent;
            border-radius: 50%;
            pointer-events: none;
            opacity: 0;
        }

        &:hover {
            .year {
                transform: translateY(-80%);
                opacity: 1;
            }
        }

        &.target-1 {
            z-index: 7;
            top: 68.9%;
            left: 67.5%;
            width: 13.6%;
            aspect-ratio: 2.8;
        }

        &.target-2 {
            z-index: 6;
            top: 68.4%;
            left: 67.5%;
            width: 20%;
            aspect-ratio: 2.8;
        }

        &.target-3 {
            z-index: 5;
            top: 68.1%;
            left: 67.5%;
            width: 27%;
            aspect-ratio: 2.8;
        }

        &.target-4 {
            transform: translate(-50%, -50%) rotate(3deg);
            z-index: 4;
            top: 67.6%;
            left: 67.7%;
            width: 33.5%;
            aspect-ratio: 2.8;
        }

        &.target-5 {
            transform: translate(-50%, -50%) rotate(3deg);
            z-index: 3;
            top: 67.4%;
            left: 67.7%;
            width: 40%;
            aspect-ratio: 2.8;
        }

        &.target-6 {
            transform: translate(-50%, -50%) rotate(3deg);
            z-index: 2;
            top: 67.5%;
            left: 67.7%;
            width: 46.5%;
            aspect-ratio: 2.7;
        }

        &.target-7 {
            transform: translate(-50%, -50%) rotate(4deg);
            z-index: 1;
            top: 68%;
            left: 67.7%;
            width: 52%;
            aspect-ratio: 2.7;
        }
    }

    &__rings {
        transform: translateX(-50%);
        position: fixed;
        z-index: 1;
        top: 0;
        left: 50%;
        width: 100%;
        height: 100%;
    }

    &__year {
        position: absolute;
        inset: 0;
        opacity: 0;

        &.active {
            opacity: 1;
        }

        > div {
            position: absolute;
            inset: 0;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0;
        }

        &.f1 .first,
        &.f2 .second,
        &.f3 .third {
            opacity: 1;
        }

        @for $i from 1 through 7 {
            &.year-#{$i}  {
                .first {
                    background-image: url('/assets/images/year-rings/#{$i}-1.png');
                }
        
                .second {
                    background-image: url('/assets/images/year-rings/#{$i}-2.png');
                }
        
                .third {
                    background-image: url('/assets/images/year-rings/#{$i}-3.png');
                }
            }
        }
    }

    &__bg-wrapper {
        position: fixed;
        z-index: 0;
        inset: 0;

        video {
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;  
        }
    }
}
