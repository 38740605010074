@use 'sass:math';

.vak-icon {
    position: relative;

    > button {
        position: relative;
        z-index: 1;
        display: block;
        appearance: none;
        width: 200px;
        aspect-ratio: 1 / 1;
        padding: 0;
        outline: none;
        border: 0;
        cursor: pointer;
        border-radius: 35%;
        background: url('/assets/images/vak-icons/algemeen-platform.png') no-repeat center bottom / cover transparent;
    }

    &::before {
        @extend %default-transition;
        position: absolute;
        z-index: 0;
        inset: -6% -6% -4% -6%;
        display: block;
        content: '';
        background: url('/assets/images/vak-icons/vak-icon-glow.png') no-repeat center bottom / contain transparent;
        opacity: 0.2;
        pointer-events: none;
    }

    &:hover::before {
        opacity: 0.75;
    }

    &.aardrijkskunde > button { background-image: url('/assets/images/vak-icons/aardrijkskunde-platform.png'); }
    &.architectuur > button { background-image: url('/assets/images/vak-icons/architectuur-platform.png'); }
    &.biologie > button { background-image: url('/assets/images/vak-icons/biologie-platform.png'); }
    &.ckv > button { background-image: url('/assets/images/vak-icons/ckv-platform.png'); }
    &.duits > button { background-image: url('/assets/images/vak-icons/duits-platform.png'); }
    &.economie > button { background-image: url('/assets/images/vak-icons/economie-platform.png'); }
    &.engels > button { background-image: url('/assets/images/vak-icons/engels-platform.png'); }
    &.frans > button { background-image: url('/assets/images/vak-icons/frans-platform.png'); }
    &.geschiedenis > button { background-image: url('/assets/images/vak-icons/geschiedenis-platform.png'); }
    &.handvaardigheid > button { background-image: url('/assets/images/vak-icons/handvaardigheid-platform.png'); }
    &.kunstgeschiedenis > button { background-image: url('/assets/images/vak-icons/kunstgeschiedenis-platform.png'); }
    &.lichamelijke-opvoeding > button { background-image: url('/assets/images/vak-icons/lichamelijke-opvoeding-platform.png'); }
    &.maatschappijleer > button { background-image: url('/assets/images/vak-icons/maatschappijleer-platform.png'); }
    &.muziek > button { background-image: url('/assets/images/vak-icons/muziek-platform.png'); }
    &.natuurkunde > button { background-image: url('/assets/images/vak-icons/natuurkunde-platform.png'); }
    &.nederlands > button { background-image: url('/assets/images/vak-icons/nederlands-platform.png'); }
    &.scheikunde > button { background-image: url('/assets/images/vak-icons/scheikunde-platform.png'); }
    &.sterrenkunde > button { background-image: url('/assets/images/vak-icons/sterrenkunde-platform.png'); }
    &.tekenen > button { background-image: url('/assets/images/vak-icons/tekenen-platform.png'); }
    &.textiele-werkvormen > button { background-image: url('/assets/images/vak-icons/textiele-werkvormen-platform.png'); }
    &.toneel-drama > button { background-image: url('/assets/images/vak-icons/toneel-drama-platform.png'); }
    &.wiskunde > button { background-image: url('/assets/images/vak-icons/wiskunde-platform.png'); }

    &__name {
        transform: translateX(-50%);
        position: absolute;
        bottom: 24px;
        left: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 120px;
        height: 32px;

        > span {
            display: block;
            color: var(--color-beige-2);
            font-size: 11.5px;
            line-height: 1.13;
            text-transform: uppercase;
            text-align: center;
        }
    }

    &__alt-img-wrapper {
        transform: translateX(-50%);
        position: absolute;
        top: 24%;
        left: 50%;
        overflow: hidden;
        font-size: 0;
        line-height: 0;
        width: 22%;
        height: auto;
        aspect-ratio: 1 / 1;

        img {
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 48%;
            width: 100%;
            height: auto;
            border-radius: 50%;
            mix-blend-mode: soft-light;
        }
    }

    @media (min-height: 950px) {
        > button {
            width: 240px;
        }

        &__name {
            bottom: 32px;
            width: 140px;
            height: 34px;

            > span {
                font-size: 13px;
            }
        }
    }

    @media (min-height: 1100px) {
        > button {
            width: 260px;
        }

        &__name {
            bottom: 36px;
            width: 152px;

            span {
                font-size: 14px;
            }
        }
    }
}