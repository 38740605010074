@use 'sass:math';

.top-bar {
    position: fixed;
    z-index: 20;
    top: 0;
    right: 0;
    left: 0;
    padding: 10px 20px;

    &--bg {
        background: var(--color-white);
    }

    &__inner {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
        padding: 8px 10px;
        background: var(--color-beige);
        border-radius: 20px;

        &--second {
            display: inline-flex;
            margin-top: 5px;
        }
    }

    &__areas {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    &__expanding-filters {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    &__right {
        display: flex;
        gap: 6px;
        align-items: center;
    }

    &__search-wrapper {
        margin-right: 30 - 6px;
        padding-right: 20px;
        border-right: 1px solid var(--color-grey);
    }

    &__search-btn {
        display: block;
        width: 38px;
        height: 38px;
        font-size: 0;
        line-height: 0;
        text-indent: -9999px;
        background: url('/assets/images/icon-search.svg') no-repeat center center transparent;
        background-size: 19px;
    }

    &__notifications {
        position: relative;

        .notifications-list {
            position: absolute;
            top: calc(100% + 18px);
            right: -25px;
        }
    }

    &__notifications-btn {
        position: relative;
        display: block;
        width: 38px;
        height: 38px;
        padding: 0;
        font-size: 0;
        line-height: 0;
        text-indent: -9999px;
        appearance: none;
        cursor: pointer;
        background: url('/assets/images/icon-bell.svg') no-repeat center center var(--color-beige-2);
        background-size: 14px auto;
        border-radius: 50%;
        border: 0;

        &::before {
            position: absolute;
            top: 9px;
            right: 9px;
            display: block;
            content: '';
            width: 6px;
            height: 6px;
            background: var(--color-coral);
            border: 2px solid var(--color-beige-2);
            border-radius: 50%;
            opacity: 0;
            pointer-events: none;
        }

        &.new-messages {
            &::before {
                opacity: 1;
            }
        }
    }

    &__my-profile-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 0;
        color: var(--color-off-black);
        font-size: math.div(14, $base-font-size) + 0rem;
        line-height: math.div(27, $base-font-size) + 0rem;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        appearance: none;
        outline: none;
        border: 0;
        border-radius: 0;
        background: transparent;

        > div {
            @extend %bg-image-cover-centered;
            width: 38px;
            height: 38px;
            background-color: var(--color-beige-2);
            border-radius: 50%;
        }
    }

    &__🍔 {
        @extend %bg-image-cover-centered;
        display: block;
        width: 38px;
        height: 38px;
        padding: 0;
        font-size: 0;
        line-height: 0;
        text-indent: -9999px;
        appearance: none;
        outline: none;
        cursor: pointer;
        border: 0;
        border-radius: 50%;
        background-image: url('/assets/images/icon-hamburger.svg');
        background-color: var(--color-beige-2);
        background-size: 16px auto;
    }

    @media (min-width: 1200px) {
        &__expanding-filters {
            display: flex;
        }
    }

    @media (min-width: 1300px) and (min-height: 850px) {
        padding: 40px 50px 20px;
    }
}