.new-comment-box {
    padding: 20px;
    background: var(--color-white);
    border-radius: 8px;

    &--loading {
        pointer-events: none;
        opacity: 0.5;
    }

    > form {
        display: flex;
        gap: 18px;
    }

    &__avatar {
        @extend %bg-image-cover-centered;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border: 1px solid var(--color-white);
        border-radius: 50%;
    }

    &__input {
        box-sizing: border-box;
        width: 100%;
        height: 2rem;
        margin: 8px 0 0;
        padding: 0;
        border: 0;
        border-radius: 0;
        text-align: left;
        font-size: 1rem;
        resize: none;
    }

    &__submit-btn {
        @extend %bg-image-cover-centered;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        margin-left: auto;
        font-size: 0;
        line-height: 0;
        text-indent: -9999px;
        background-image: url('/assets/images/icon-post-comment.svg');
        background-color: var(--color-mustard);
        background-size: 14px auto;
        border-radius: 50%;
    }

    &__closed {
        display: flex;
        align-items: center;
        gap: 18px;
        color: var(--color-grey);

        &::before {
            display: block;
            content: '';
            width: 44px;
            height: 44px;
            background: url('/assets/images/icon-lock.svg') no-repeat center center var(--color-beige-2);
            background-size: 12px auto;
            border-radius: 50%;
        }
    }
}