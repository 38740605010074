.feedback-module-trigger {
    position: fixed;
    right: 55px;
    bottom: 105px;
    z-index: 90;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 0;
    background: url('/assets/images/icon-feedback.svg') no-repeat 54% center var(--color-beige);
    background-size: 50%;
    appearance: none;
    outline: none;
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    text-indent: -9999px;
}

.feedback-module {
    position: fixed;
    z-index: 100;
    inset: 0;
    overflow: auto;
    display: none;
    background: rgba(0, 0, 0, 0.5);
    padding: 80px 0;

    &.show {
        display: flex;
    }

    &__inner {
        position: relative;
        box-sizing: border-box;
        margin: auto;
        width: 90%;
        max-width: 450px;
        padding: 50px;
        border-radius: 14px;
        background: var(--color-beige-2);
    }

    &__close-btn {
        transform: translateY(-100%);
        position: absolute;
        top: -14px;
        right: 0;
        display: block;
        width: 44px;
        height: 44px;
        appearance: none;
        outline: none;
        cursor: pointer;
        border: 0;
        font-size: 0;
        line-height: 0;
        text-indent: -9999px;
        border-radius: 50%;
        background: url('/assets/images/button-close.png') no-repeat center center / cover transparent;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
            @extend %default-font-bold;
        }

        p {
            display: block;
            margin: 0;
            margin-bottom: 10px;
        }

        input,
        textarea {
            width: 100%;
            background: var(--color-white);
            text-align: left;
        }

        textarea {
            resize: none;
        }

        button {
            margin-top: 10px;
            margin-inline: auto;
        }
    }

    &.loading &__form {
        opacity: 0.5;
        pointer-events: none;
    }

    &__loading,
    &__success {
        display: block;
        text-align: center;
        margin-top: 20px;
    }

    &__success {
        color: var(--color-green);
    }
}