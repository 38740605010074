@use 'sass:math';

.topic-post {
    position: relative;
    max-width: 100ch;

    &--loading {
        opacity: 0.5;
        pointer-events: none;

        &::before {
            transform: translate(-50%, -50%);
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            display: block;
            content: '';
            width: 35px;
            height: 35px;
            background: url('/assets/images/icon-loading.svg') no-repeat center center transparent;
            background-size: 85%;
            opacity: 1;
            animation-name: loading-animation;
            animation-duration: 1.8s;
            animation-iteration-count: infinite;
        }
    }

    &__main {
        padding: 16px 20px;
        background: var(--color-white);
        border-radius: 8px;
    }

    &--pinned &__main {
        background: var(--color-beige-2);
    }

    &__author {
        display: flex;
        align-items: center;
        gap: 14px;

        .avatar {
            @extend %bg-image-cover-centered;
            width: 32px;
            height: 32px;
            background-color: var(--color-beige-2);
            border-radius: 50%;
        }

        .meta {
            > span {
                display: block;

                &.date {
                    color: var(--color-grey);

                    &:first-letter {
                        text-transform: uppercase;
                    
                    }
                }
            }
        }
    }

    &__meta-options {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        gap: 10px;

        .pin-indicator {
            display: block;
            width: 40px;
            height: 40px;
            background: url('/assets/images/icon-pin.svg') no-repeat center center var(--color-beige-3);
            background-size: 14px auto;
            border-radius: 50%;
            font-size: 0;
            line-height: 0;
            text-indent: -9999px;
            pointer-events: none;
        }

        > button {
            position: relative;
            font-size: 0;
            line-height: 0;
            text-indent: -9999px;
            width: 30px;
            height: 30px;
            appearance: none;
            outline: none;
            border: 0;
            border-radius: 0;
            cursor: pointer;
            background: url('/assets/images/icon-menu-dots.svg') no-repeat center center transparent;
            background-size: 26px auto;

            &:hover {
                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: -25px;
                    left: -80px;
                    display: block;
                    content: '';
                }
            }
        }

        ul {
            position: absolute;
            top: 100%;
            right: -15px;
            display: none;
            flex-direction: column;
            gap: 16px;
            padding: 24px 30px 24px 20px;
            white-space: nowrap;
            background: var(--color-beige-2);
            border-radius: 14px;

            &:hover {
                display: flex;
            }

            .topic-post--pinned & {
                background-color: var(--color-beige);
            }

            li {
                display: block;
            }

            button {
                display: flex;
                align-items: center;
                gap: 16px;
                padding: 0;
                appearance: none;
                outline: none;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }

                &::before {
                    display: block;
                    content: '';
                    width: 17px;
                    height: 17px;
                }

                &.edit::before {
                    @extend %bg-image-cover-centered;
                    background-image: url('/assets/images/icon-edit-btn-grey.svg');
                    background-size: 17px auto;
                }

                &.delete::before {
                    @extend %bg-image-cover-centered;
                    background-image: url('/assets/images/icon-delete-btn-grey.svg');
                    background-size: 13px auto;
                }
            }
        }

        > button:hover ~ ul {
            display: flex;
        }
    }

    &__title {
        display: block;
        margin-top: 20px;
        color: var(--color-purple);
    }

    &__image {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 800px;
        margin-top: 22px;
    }

    &__content {
        margin-top: 12px;
    }

    &__embed {
        margin-top: 20px;

        iframe {
            width: 100%;
            height: auto;
            aspect-ratio: 16 / 9;
        }
    }

    &__meta {
        display: flex;
        gap: 30px;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;

        &--no-margin {
            margin-top: 0;
        }
    }

    &__followers {
        display: flex;

        li {
            @extend %bg-image-cover-centered;
            box-sizing: border-box;
            display: block;
            width: 40px;
            height: 40px;
            border: 2px solid var(--color-white);
            border-radius: 50%;
            background-color: var(--color-beige-2);
            user-select: none;
            pointer-events: none;

            &.additional {
                @extend %default-font-bold;
                color: var(--color-white);
                text-align: center;
                font-size: math.div(14, $base-font-size) + 0rem;
                line-height: 35px;
                background: var(--color-blue);
            }
        }

        > * + * {
            margin-left: -14px;
        }
    }

    &__btn-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 6px;
    }

    &__comments {
        position: relative;
        margin-top: 6px;
        margin-left: 58px;

        &::before {
            transform: translateX(calc(-100% - 6px));
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            content: '';
            width: 28px;
            height: 28px;
            background: url('/assets/images/comments-bow.svg') 100% 0 transparent;
            background-size: 28px auto;
        }
    }

    &__comment-box {
        margin-top: 6px;
    }

    .comment-box {
        display: flex;
        gap: 18px;
        padding: 20px;
        background: var(--color-white);
        border-radius: 8px;

        &__avatar {
            @extend %bg-image-cover-centered;
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            border: 1px solid var(--color-white);
            border-radius: 50%;
        }

        &__name {
            @extend %default-font-bold;
            display: block;
            color: var(--color-purple);
        }
    }

    &__show-more-comments {
        @extend %default-font;
        display: block;
        margin-bottom: 10px;
        padding: 10px 20px;
        color: var(--color-off-black);
        appearance: none;
        outline: none;
        border: 0;
        border-radius: none;
        background: transparent;
        opacity: 0.8;

        &:hover {
            text-decoration: underline;
        }
    }

    &__new-comment-box {
        margin-top: 6px;
    }

    @media (min-width: 1300px) and (min-height: 850px) {
        margin-top: 34px;

        &__main {
            padding: 26px 30px;
        }
    }
}