@use 'sass:math';

.tag {
    @extend %default-transition;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 4px 19px;
    color: var(--color-white);
    font-size: math.div(14, $base-font-size) + 0rem;
    line-height: math.div(27, $base-font-size) + 0rem;
    letter-spacing: 1.68px;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 14px;
    background: var(--color-mustard);
    appearance: none;
    user-select: none;

    &--link {
        padding: 0;

        a {
            display: block;
            padding: 4px 19px;
            text-decoration: none;
        }
    }

    &--link.tag--alt:hover {
        color: var(--color-white);
        background: var(--color-mustard);
    }

    &--alt {
        color: var(--color-main-text);
        background: var(--color-beige-2);
    }

    &__close-btn {
        display: block;
        width: 15px;
        height: 15px;
        font-size: 0;
        line-height: 0;
        text-indent: -9999px;
        background: url('/assets/images/icon-close-regular.svg') no-repeat center center transparent;
        background-size: 10px;
    }
}