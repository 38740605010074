.notifier {
    @extend %default-transition;
    transform: translate(-50%, -300%);
    position: fixed;
    z-index: 20;
    top: 20px;
    left: 50%;
    max-width: 95vw;
    padding: 10px 20px;
    color: var(--color-white);
    text-align: center;
    background: var(--color-coral);
    border-radius: 40px;
    opacity: 0;

    &.in-progress,
    &.success,
    &.error {
        transform: translate(-50%, 0);
        opacity: 1;
    }

    > span {
        display: none;
    }

    &.in-progress {
        > .in-progress {
            display: block;
        }
    }

    &.success {
        background-color: var(--color-blue);

        > .success {
            display: block;
        }
    }

    &.error {
        background-color: var(--color-red);

        > .error {
            display: block;
        }
    }
}