.lpb-form-bottom-bar {
    width: 100%;
    margin-top: 40px;
    padding: 35px 0;
    border-top: 1px solid #e7e2d9;

    &__inner {
        @extend %vakcur-type-intro;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin-inline: auto;
    }

    &__left {
        display: flex;
        align-items: center;
        gap: 50px;

        &--small-gap {
            gap: 20px;
        }
    }

    &__steps {
        span {
            color: var(--color-black);

            &:nth-child(2) {
                color: var(--color-grey);
            }
        }
    }

    &__section {
        span {
            color: var(--color-grey);

            &:nth-child(2) {
                @extend %default-font-bold;
                display: inline-block;
                color: var(--color-black);
                margin-left: 5px;
            }
        }
    }

    &__progress-wrapper {
        display: flex;
        align-items: center;
        gap: 33px;

        .progress {
            transform: translateY(1px);
            width: 100px;
            background: rgba(var(--color-blue-rgb), 0.2);

            >div {
                height: 6px;
                background: var(--color-blue);
            }
        }

        .text {
            display: block;
            opacity: 0.4;

            &.completed {
                opacity: 1;
                color: var(--color-blue);
            }
        }
    }

    &__user {
        @extend %default-font-bold;
    }

    &__link {
        color: var(--color-grey);
    }
}