.topic {
    position: relative;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1.2fr 2.8fr 1.2fr;
    gap: 35px 20px;
    align-items: start;
    width: 100%;
    margin-top: 20px;
    padding: 0 30px 100px;


    // TODO: This selector below should be deleted when all topics are in use. We can also remove the wrapper div
    .topic-select-wrapper {
        .custom-select__options {
            li {
                button {
                    pointer-events: none;
                    color: rgba(0, 0, 0, 0.3);
                }

                &:first-child button,
                &:nth-child(4) button,
                &:nth-child(5) button,
                &:nth-child(7) button {
                    pointer-events: initial;
                    color: var(--color-main-text);
                }
            }
        }
    }


    &__left,
    &__right {
        position: sticky;
        top: 74 + 20px;

        >*+* {
            margin-top: 13px;
        }
    }

    &__right {
        overflow: auto;
        box-sizing: border-box;
        max-height: calc(100dvh - 95px);
        margin-top: 4px;
        padding-top: 17px;
        padding-bottom: 30px;
    }

    &__left-btn-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px 15px;
    }

    &__main {
        position: relative;

        >*+* {
            margin-top: 25px;
        }

        &::before {
            position: absolute;
            top: 70px;
            left: -10px;
            display: block;
            content: '';
            width: 1px;
            height: 100%;
            background: var(--color-beige-3);
        }

        &--no-border {
            &::before {
                display: none;
            }
        }

        .custom-select {
            z-index: 1;
        }
    }

    &__tag-row {
        display: flex;
        flex-wrap: wrap;
        gap: 6px 8px;

        &--top-margin {
            margin-top: 10px;
        }
    }

    &__load-more-wrapper {
        display: flex;
        justify-content: center;
    }

    .widget {
        padding: 20px 18px;
        background: var(--color-beige-2);
        border-radius: 14px;

        &--alt {
            padding: 0;
            background: transparent;
            border-radius: 0;
        }

        &__subject {
            @extend %type-tag;
            display: block;
            color: var(--color-grey);
        }

        &__topic-list {
            display: block;
            margin-top: 34px;

            >*+* {
                margin-top: 6px;
            }

            li {
                display: block;

                a {
                    text-decoration: underline;
                }

                &.current a,
                a:hover {
                    color: var(--color-mustard);
                    text-decoration: underline;
                }
            }
        }

        &--alt .widget__topic-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            li {
                a {
                    text-decoration: none;
                }
            }

            .btn {
                line-height: 1.4;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }

        &__post-preview {
            margin-top: 16px;

            a {
                text-decoration: none;
            }

            &__title {
                display: block;
                color: var(--color-purple);
                word-break: break-word;
            }

            &__followers {
                display: block;
                margin-top: 16px;
                color: var(--color-grey);
            }
        }
    }

    @media (min-width: 1300px) and (min-height: 850px) {
        gap: 35px 70px;
        margin-top: 40px;
        padding: 0 50px 180px;

        &__left,
        &__right {
            top: 114 + 40px;
        }

        &__right {
            padding-top: 0;
            margin-top: 12px;
            max-height: calc(100dvh - 154px);
        }

        &__left-btn-wrapper {
            margin-bottom: 16px;
        }

        &__main {
            >*+* {
                margin-top: 30px;
            }

            &::before {
                top: 75px;
                left: -35px;
            }
        }

        &__tag-row {

            &--top-margin {
                margin-top: 10px;
            }
        }

        .widget {
            padding: 24px 22px;

            &__topic-list {
                margin-top: 25px;
            }
        }
    }
}