@use 'sass:math';

.btn {
    @extend %default-transition;
    box-sizing: border-box;
    display: inline-block;
    padding: 7px 15px;
    color: var(--color-off-black);
    font-size: math.div(14, $base-font-size) + 0rem;
    line-height: 1.3;
    letter-spacing: 1.5px;
    text-decoration: none;
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 14px;
    background: var(--color-beige-2);
    appearance: none;
    user-select: none;

    &:hover {
        background-color: var(--color-beige-2-hover);
    }

    &[disabled] {
        opacity: 0.4;
        pointer-events: none;
    }

    &--active {
        color: var(--color-beige);
        background-color: var(--color-off-black);
    }

    &--no-interaction {
        pointer-events: none;
    }

    &--white {
        background: var(--color-beige);
    }

    &--text-light {
        color: var(--color-grey);
    }

    &--beige {
        background-color: var(--color-beige);
    }

    &--mustard {
        color: var(--color-beige);
        background-color: var(--color-mustard);

        &:hover {
            background-color: var(--color-mustard-hover);
        }
    }

    // &--mustard-light {
    //     color: var(--color-mustard-light);
    //     background-color: var(--color-beige-2);

    //     &:hover {
    //         background-color: var(--color-mustard-hover);
    //     }
    // }

    &--mustard-outline {
        color: var(--color-mustard);
        border-color: var(--color-mustard);
        background-color: transparent;

        &:hover {
            color: var(--color-beige);
            background-color: var(--color-blue);
            border-color: var(--color-blue);
        }
    }

    &--coral {
        color: var(--color-beige);
        background-color: var(--color-coral);

        &:hover {
            background-color: var(--color-coral-hover);
        }
    }

    // &--coral-light {
    //     color: var(--color-coral-light);
    //     background-color: var(--color-beige-2);

    //     &:hover {
    //         background-color: var(--color-coral-hover);
    //     }
    // }

    &--blue {
        color: var(--color-beige);
        background-color: var(--color-blue);

        &:hover {
            background-color: var(--color-blue-hover);
        }
    }

    // &--blue-light {
    //     color: var(--color-blue-light);
    //     background-color: var(--color-beige-2);

    //     &:hover {
    //         background-color: var(--color-blue-hover);
    //     }
    // }

    &--green {
        color: var(--color-beige);
        background-color: var(--color-green);
        border: 1px solid var(--color-green);

        &:hover {
            background-color: var(--color-green-hover);
        }
    }

    // &--green-light {
    //     color: var(--color-green-light);
    //     background-color: var(--color-beige-2);

    //     &:hover {
    //         background-color: var(--color-green-hover);
    //     }
    // }

    &--arrow {
        display: flex;
        gap: 10px;
        padding-inline: 0;
        align-items: center;
        background: transparent;

        > span {
            @extend %default-transition;
            display: block;
            flex-shrink: 0;
            width: 38px;
            height: 38px;
            background-color: var(--color-beige-2);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 9px auto;
            border-radius: 50%;
        }

        &:hover {
            color: var(--color-off-black);
            background: transparent;

            > span {
                transform: translateX(-3px);
            }
        }
    }

    &--arrow-reversed {
        flex-direction: row-reverse;

        > span {
            transform: rotate(180deg);
        }

        &:hover {
            > span {
                transform: translateX(3px) rotate(180deg);
            }
        }
    }

    &--arrow-inline {
        display: flex;
        align-items: center;
        gap: 10px;

        &::after {
            transform: rotate(-90deg);
            display: block;
            content: '';
            width: 10px;
            height: 6px;
            background: url('/assets/images/icon-arrow-inline-down-dark.svg') no-repeat 0 0 / cover transparent;
        }
    }

    &--mustard.btn--arrow-inline {
        &::after {
            background-image: url('/assets/images/icon-arrow-inline-down.svg');
        }
    }

    &--arrow-inline-down {
        &::after {
            transform: rotate(0deg);
        }
    }

    &--alt {
        padding: 6px 12px;
        border-radius: 30px;
    }

    @media (min-width: 1300px) and (min-height: 850px) {
        padding: 9px 19px;

        &--arrow-inline {
            gap: 16px;
        }

        &--alt {
            padding: 10px 17px;
        }
    }
}