@use 'sass:math';

.vakcurriculum-btn {
    @extend %default-transition;
    @extend %vakcur-type-buttons;
    box-sizing: border-box;
    display: inline-block;
    padding: 15px 20px;
    color: var(--color-mustard);
    font-size: math.div(18, $base-font-size) + 0rem;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid var(--color-mustard);
    border-radius: 30px;
    background: transparent;
    appearance: none;
    user-select: none;

    &:hover {
        color: var(--color-white);
        background-color: var(--color-mustard);
    }

    &[disabled] {
        opacity: 0.4;
        pointer-events: none;
    }

    &--simple {
        border: 0;
        padding: 0;
        border-radius: 0;

        &:hover {
            color: var(--color-mustard);
            background-color: transparent;
        }
    }

    &--small {
        font-size: math.div(16, $base-font-size) + 0rem;
    }

    &--purple {
        color: var(--color-purple);
        border-color: var(--color-beige-3);

        &:hover {
            color: var(--color-white);
            background-color: var(--color-purple);
        }
    }

    &--full-purple {
        color: var(--color-purple);
        border-color: var(--color-purple);

        &:hover {
            color: var(--color-white);
            background-color: var(--color-purple);
        }
    }

    &--green {
        color: var(--color-green);
        border-color: var(--color-green);

        &:hover {
            color: var(--color-white);
            background-color: var(--color-green);
        }
    }

    &--red {
        color: var(--color-red);
        border-color: var(--color-red);

        &:hover {
            color: var(--color-white);
            background-color: var(--color-red);
        }
    }

    &--grey {
        color: var(--color-grey);
        border-color: var(--color-grey);

        &:hover {
            color: var(--color-white);
            background-color: var(--color-grey);
        }
    }

    &--small {
        padding: 8px 15px;
        font-size: math.div(15, $base-font-size) + 0rem;
    }

    &--full-white {
        color: var(--color-mustard);
        background: var(--color-white);
        border-color: var(--color-white);
    }

    &--full-mustard {
        color: var(--color-white);
        background: var(--color-mustard);
        border-color: var(--color-mustard);

        &:hover {
            background-color: var(--color-grey);
            border-color: var(--color-grey);
        }
    }

    &--solid {
        color: var(--color-white);
        background-color: var(--color-mustard);

        &.vakcurriculum-btn--purple {
            background-color: var(--color-purple);
            border-color: var(--color-purple);
        }
    }
}