.arrow-btn {
    display: block;
    padding: 0;
    width: 38px;
    height: 38px;
    font-size: 0;
    line-height: 0;
    text-indent: -9999px;
    background: url('/assets/images/arrow-btn.png') no-repeat center center / cover transparent;
    border-radius: 50%;
}