.lpb-form-summary-field {
    &__fields {
        display: grid;
        gap: 20px;
    }

    &__field {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }

    .field {
        display: flex;
        gap: 50px;

        &__title {
            @extend %vakcur-type-heading-3;
            display: block;
            width: 30%;
            margin-left: 20%;
            color: var(--color-purple);
        }

        &__content {
            @extend %vakcur-type-intro;
            width: 50%;
            padding-top: 1px;
            color: var(--color-grey);
        }
    }
}