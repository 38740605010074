@use 'sass:math';

.lpb-vakcurriculum-form-header {
    position: fixed;
    z-index: 10;
    top: 60px;
    right: 0;
    left: 0;
    box-sizing: border-box;
    pointer-events: none;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin-inline: auto;
    }

    &__start {
        display: flex;
        align-items: center;
        gap: 65px;
        pointer-events: initial;
    }

    &__logo {
        position: relative;
        z-index: 1;
        display: block;
        width: 54px;
        height: 54px;
        font-size: 0;
        line-height: 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 54px auto;
        pointer-events: initial;
    }

    &__title {
        @extend %font-antropos;
        display: block;
        font-size: math.div(24, $base-font-size) + 0rem;
        line-height: math.div(26, $base-font-size) + 0rem;
        text-transform: uppercase;
    }

    &__welcome {
        position: relative;
        text-align: right;
        pointer-events: initial;

        span {
            @extend %font-antropos;
            display: block;
            font-size: math.div(24, $base-font-size) + 0rem;
            line-height: math.div(26, $base-font-size) + 0rem;
            text-transform: uppercase;

            &:nth-child(1) {
                color: var(--color-purple);
            }

            &:nth-child(2) {
                color: var(--color-coral);
            }
        }
    }

    &__cards {
        position: relative;
        text-align: right;
        pointer-events: initial;

        button {
            @extend %font-antropos;
            display: flex;
            gap: 16px;
            align-items: center;
            padding: 0;
            color: var(--color-purple);
            font-size: math.div(24, $base-font-size) + 0rem;
            line-height: math.div(26, $base-font-size) + 0rem;
            text-transform: uppercase;
            appearance: none;
            outline: none;
            cursor: pointer;
            border: 0;
            background: transparent;

            span {
                display: block;
                width: 30px;
                height: 30px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 28px auto;
            }
        }
    }

    &__logout {
        transform: translateY(-100%);
        position: absolute;
        top: -15px;
        right: 0;
        display: block;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}