@use 'sass:math';

.page-start {
    position: relative;
    width: 100vw;
    height: 100dvh;

    &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        pointer-events: none;
        background: radial-gradient(
            circle at top left,
            rgba(0, 0, 0, 0.25) 0%,
            rgba(0, 0, 0, 0.25) 30%,
            transparent 70%
        );
    }

    &__main-wrapper {
        position: relative;
        z-index: 2;
        max-width: 520px;
        padding-top: 160px;
        padding-left: 60px;
        color: var(--color-beige-2);
    }

    &__title {
        @extend %font-2;
        display: block;
        font-size: math.div(70, $base-font-size) + 0rem;
        line-height: math.div(76, $base-font-size) + 0rem;
        text-transform: uppercase;

        &--small {
            font-size: math.div(50, $base-font-size) + 0rem;
            line-height: math.div(56, $base-font-size) + 0rem;
        }
    }

    &__desc {
        max-width: 450px;
        margin-top: 30px;

        p,
        ul,
        ol {
            @extend %type-intro;
        }
    }

    &__grade-select {
        display: flex;
        align-items: center;
        gap: 11px;
        margin-top: 30px;

        span {
            @extend %type-intro;
            display: block;
        }
    }

    &__bg-wrapper {
        position: absolute;
        z-index: 0;
        inset: 0;
        overflow: hidden;

        video {
            transform: translateX(-50%);
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 100%;
            height: 100%;
        }
    }

    @media (min-width: 1000px) and (min-height: 850px) {
        &__main-wrapper {
            max-width: 620px;
            padding-top: 230px;
            padding-left: 140px;
        }

        &__title {
            font-size: math.div(80, $base-font-size) + 0rem;
            line-height: math.div(86, $base-font-size) + 0rem;

            &--small {
                font-size: math.div(60, $base-font-size) + 0rem;
                line-height: math.div(66, $base-font-size) + 0rem;
            }
        }

        &__desc {
            margin-top: 40px;
        }

        &__grade-select {
            margin-top: 40px;
        }
    }
}