.menskunde-topics {
    position: relative;
    overflow: hidden;
    width: 100dvw;
    height: 100dvh;
    background-color: black;

    &__clouds {
        transform: translateX(-50%);
        position: absolute;
        z-index: 1;
        top: 0;
        left: 50%;
        width: 100%;
        height: 100%;

        .prikbord-topics__cloud {
            opacity: 0;
        }

        &.visible .prikbord-topics__cloud {
            opacity: 1;
        }
    }

    &__cloud {
        transform-origin: center top;
        position: absolute;
        top: 0;
        left: 0;
        width: 23%;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 100% auto;
        aspect-ratio: 512 / 1280;
        pointer-events: none;
        opacity: 0;

        &::before {
            @extend %medium-transition;
            position: absolute;
            z-index: 0;
            inset: 0;
            display: block;
            content: '';
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: 100% auto;
            pointer-events: none;
            opacity: 0;
        }

        &.active {
            &::before {
                opacity: 1;
            }
        }

        > button {
            transform: translate(-50%, -38%);
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 50%;
            display: block;
            width: 50%;
            height: auto;
            aspect-ratio: 1 / 1;
            padding: 0;
            font-size: 0;
            line-height: 0;
            text-indent: -9999px;
            text-decoration: none;
            border-radius: 50%;
            pointer-events: initial;
            background-color: transparent;
        }

        &.topic-1,
        &.topic-11 {
            background-image: url('/assets/images/stars/1.png');

            &::before {
                background-image: url('/assets/images/stars/1-glow.png');
            }

            a {
                transform: translate(-50%, -29%);
                width: 55%;
            }
        }

        &.topic-2 {
            background-image: url('/assets/images/stars/2.png');

            &::before {
                background-image: url('/assets/images/stars/2-glow.png');
            }

            a {
                transform: translate(-50%, -69%);
                width: 37%;
            }
        }

        &.topic-3 {
            background-image: url('/assets/images/stars/3.png');

            &::before {
                background-image: url('/assets/images/stars/3-glow.png');
            }

            a {
                transform: translate(-50%, -47%);
                width: 45%;
            }
        }

        &.topic-4 {
            background-image: url('/assets/images/stars/4.png');

            &::before {
                background-image: url('/assets/images/stars/4-glow.png');
            }

            a {
                transform: translate(-50%, -47%);
                width: 45%;
            }
        }

        &.topic-5 {
            background-image: url('/assets/images/stars/5.png');

            &::before {
                background-image: url('/assets/images/stars/5-glow.png');
            }

            a {
                transform: translate(-50%, -76%);
                width: 35%;
            }
        }

        &.topic-6 {
            background-image: url('/assets/images/stars/6.png');

            &::before {
                background-image: url('/assets/images/stars/6-glow.png');
            }

            a {
                transform: translate(-50%, -76%);
                width: 35%;
            }
        }
        &.topic-7 {
            background-image: url('/assets/images/stars/7.png');

            &::before {
                background-image: url('/assets/images/stars/7-glow.png');
            }

            a {
                transform: translate(-50%, -76%);
                width: 35%;
            }
        }

        &.topic-8 {
            background-image: url('/assets/images/stars/8.png');

            &::before {
                background-image: url('/assets/images/stars/8-glow.png');
            }

            a {
                transform: translate(-50%, -76%);
                width: 35%;
            }
        }

        &.topic-9 {
            background-image: url('/assets/images/stars/9.png');

            &::before {
                background-image: url('/assets/images/stars/9-glow.png');
            }

            a {
                transform: translate(-50%, -76%);
                width: 35%;
            }
        }

        &.topic-10 {
            background-image: url('/assets/images/stars/10.png');

            &::before {
                background-image: url('/assets/images/stars/10-glow.png');
            }

            a {
                transform: translate(-50%, -76%);
                width: 35%;
            }
        }


        &.topic-1 {
            transform: scale(1.2) rotate(3deg);
            z-index: 2;
            left: 7%;
            height: 40vh;
        }

        &.topic-2 {
            transform: scale(0.9) rotate(2deg);
            filter: blur(0.2px);
            z-index: 0;
            left: 22%;
            height: 70vh;
        }

        &.topic-3 {
            transform: scale(1);
            z-index: 1;
            left: 28%;
            height: 46vh;
        }

        &.topic-4 {
            transform: scale(0.9);
            filter: blur(0.2px);
            left: 75%;
            height: 75vh;
        }

        &.topic-5 {
            transform: scale(1.2);
            left: 40%;
            height: 55vh;
        }

        &.topic-6 {
            transform: scale(0.8) rotate(2deg);
            filter: blur(0.2px);
            z-index: 1;
            left: -2%;
            height: 81vh;
        }

        &.topic-7 {
            transform: scale(1.3) rotate(-3deg);
            filter: blur(0.5px);
            z-index: 1;
            left: 53%;
            height: 35vh;
        }

        &.topic-8 {
            transform: scale(0.7) rotate(-2deg);
            filter: blur(0.2px);
            left: 65%;
            height: 66vh;
        }

        &.topic-9 {
            transform: scale(0.8) rotate(-2deg);
            right: 27%;
            left: auto;
            height: 90vh;
        }

        &.topic-10 {
            transform: scale(1.2) rotate(-2deg);
            left: 8%;
            height: 70vh;
        }
    }

    &__hover-title {
        @extend %font-2;
        transform: translateX(-50%);
        position: absolute;
        z-index: 2;
        bottom: 23%;
        left: 50%;
        width: 90%;
        display: block;
        color: var(--color-beige-2);
        font-size: 6.4vw;
        line-height: 1;
        text-transform: uppercase;
        text-align: center;
        white-space: wrap;
        pointer-events: none;
    }

    &__bg-wrapper {
        position: fixed;
        z-index: 0;
        inset: 0;

        video {
            transform: translateX(-50%);
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 100%;
            height: 100%;  
        }
    }

    @media (orientation: portrait) {
        &__cloud {
            top: -8%;
        }
    }
}
