// Global colors
:root {
    --color-black: #211905;
    --color-off-black: #211905;
    --color-white: white;

    --color-purple: #851a4d;
    --color-coral: #ef7e6c;
    --color-coral-light: #f29b8d;
    --color-coral-hover: #e26653;
    --color-mustard: #cb9b34;
    --color-mustard-light: #deba6d;
    --color-mustard-hover: #bc8d27;
    --color-green: #255525;
    --color-green-light: #3f6b3f;
    --color-green-hover: rgb(34, 79, 34);
    --color-blue: #63a6a3;
    --color-blue-light: #8ec4c2;
    --color-blue-rgb: 99, 166, 163;
    --color-blue-hover: #559491;
    --color-beige: #fefaf4;
    --color-beige-rgb: 254, 250, 244;
    --color-beige-2: #fdf3e4;
    --color-beige-2-hover: #ffefd6;
    --color-beige-3: #f2e7d5;
    --color-grey: #a9a7a3;
    --color-red: #d80d0d;

    --color-main-text: var(--color-off-black);
}