@use 'sass:math';

$base-font-size: 16;

@font-face {
    font-family: 'antroposfreefont';
    src: url('./../../fonts/antropos_freefont-webfont.woff2') format('woff2'),
         url('./../../fonts/antropos_freefont-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PPHatton-Medium';
    src: url('./../../fonts/PPHatton-Medium.otf') format('opentype'),
         url('./../../fonts/PPHatton-Medium.ttf') format('truetype'),
         url('./../../fonts/PPHatton-Medium.woff') format('woff'),
         url('./../../fonts/PPHatton-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PPHatton-Ultralight';
    src: url('./../../fonts/PPHatton-Ultralight.otf') format('opentype'),
         url('./../../fonts/PPHatton-Ultralight.ttf') format('truetype'),
         url('./../../fonts/PPHatton-Ultralight.woff') format('woff'),
         url('./../../fonts/PPHatton-Ultralight.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PPHatton-UltralightItalic';
    src: url('./../../fonts/PPHatton-UltralightItalic.otf') format('opentype'),
         url('./../../fonts/PPHatton-UltralightItalic.ttf') format('truetype'),
         url('./../../fonts/PPHatton-UltralightItalic.woff') format('woff'),
         url('./../../fonts/PPHatton-UltralightItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: 'Manrope';
//     src: url('Manrope-Regular.woff2') format('woff2'),
//         url('Manrope-Regular.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

@font-face {
    font-family: 'Manrope-light';
    src: url('./../../fonts/Manrope-Light.woff2') format('woff2'),
        url('./../../fonts/Manrope-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Manrope';
//     src: url('Manrope-Medium.woff2') format('woff2'),
//         url('Manrope-Medium.woff') format('woff');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }

@font-face {
    font-family: 'Manrope-semibold';
    src: url('./../../fonts/Manrope-SemiBold.woff2') format('woff2'),
        url('./../../fonts/Manrope-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-bold';
    src: url('./../../fonts/Manrope-Bold.woff2') format('woff2'),
        url('./../../fonts/Manrope-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Manrope-extrabold';
//     src: url('Manrope-ExtraBold.woff2') format('woff2'),
//         url('Manrope-ExtraBold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Manrope';
//     src: url('Manrope-ExtraLight.woff2') format('woff2'),
//         url('Manrope-ExtraLight.woff') format('woff');
//     font-weight: 200;
//     font-style: normal;
//     font-display: swap;
// }


%default-font {
    font-family: 'Manrope-light', sans-serif;
    font-weight: 300;
}

%default-font-semibold {
    font-family: 'Manrope-semibold', sans-serif;
    font-weight: 600;
}

%default-font-bold {
    font-family: 'Manrope-bold', sans-serif;
    font-weight: bold;
}


%font-antropos {
    font-family: antroposfreefont, sans-serif;
    font-weight: normal;
}


%font-2 {
    font-family: 'PPHatton-Medium', sans-serif;
    font-weight: normal;
}

%font-2-ultralight {
    font-family: 'PPHatton-Ultralight', sans-serif;
    font-weight: normal;
}

%font-2-ultralight-italic {
    font-family: 'PPHatton-UltralightItalic', sans-serif;
    font-weight: normal;
}

%type-body {
    @extend %default-font;
    font-size: math.div(16, $base-font-size) + 0rem;
    line-height: math.div(26, $base-font-size) + 0rem;
    letter-spacing: 0.5px;
}

%type-tag {
    font-size: math.div(14, $base-font-size) + 0rem;
    line-height: math.div(16, $base-font-size) + 0rem;
    letter-spacing: 1.68px;
    text-transform: uppercase;
}

%type-intro {
    @extend %default-font-semibold;
    font-size: math.div(18, $base-font-size) + 0rem;
    line-height: math.div(32, $base-font-size) + 0rem;
}

%type-heading-1 {
    @extend %font-2-ultralight;
    font-size: math.div(40, $base-font-size) + 0rem;
    line-height: math.div(50, $base-font-size) + 0rem;
    letter-spacing: 1.68px;

    @media (min-width: 850px) and (min-height: 850px) {
        font-size: math.div(60, $base-font-size) + 0rem;
        line-height: math.div(70, $base-font-size) + 0rem;
    }
}

%type-heading-2 {
    font-size: math.div(30, $base-font-size) + 0rem;
    line-height: math.div(36, $base-font-size) + 0rem;
}

%type-heading-3 {
    font-family: 'PPHatton-Ultralight', sans-serif;
    font-size: math.div(26, $base-font-size) + 0rem;
    line-height: math.div(32, $base-font-size) + 0rem;

    @media (min-width: 1300px) and (min-height: 850px) {
        font-size: math.div(30, $base-font-size) + 0rem;
        line-height: math.div(36, $base-font-size) + 0rem;
    }
}


.heading\:1 {
    @extend %type-heading-1;
}

.heading\:3 {
    @extend %type-heading-3;
}



// Vakcurriculum form styles
%vakcur-type-heading-1 {
    @extend %default-font-bold;
    font-size: math.div(33, $base-font-size) + 0rem;
    line-height: math.div(41, $base-font-size) + 0rem;

    @media (min-height: 950px) {
        font-size: math.div(38, $base-font-size) + 0rem;
        line-height: math.div(46, $base-font-size) + 0rem;
    }
}

%vakcur-type-heading-2 {
    @extend %default-font-bold;
    font-size: math.div(30, $base-font-size) + 0rem;
    line-height: math.div(40, $base-font-size) + 0rem;
}

%vakcur-type-heading-3 {
    @extend %default-font-bold;
    font-size: math.div(18, $base-font-size) + 0rem;
    line-height: math.div(26, $base-font-size) + 0rem;
}

%vakcur-type-intro {
    @extend %default-font;
    font-size: math.div(18, $base-font-size) + 0rem;
    line-height: math.div(24,$base-font-size) + 0rem;
}

%vakcur-type-buttons {
    @extend %default-font;
    font-size: math.div(18, $base-font-size) + 0rem;
    line-height: math.div(27, $base-font-size) + 0rem;
}

%vakcur-type-info {
    @extend %default-font;
    font-size: math.div(16, $base-font-size) + 0rem;
    line-height: math.div(24, $base-font-size) + 0rem;
}

%vakcur-type-tags {
    @extend %default-font;
    font-size: math.div(15, $base-font-size) + 0rem;
    line-height: math.div(27, $base-font-size) + 0rem;
}