@use 'sass:math';

.info-modal {
    position: fixed;
    z-index: 20;
    inset: 0;
    overflow: auto;
    display: none;
    padding: 50px 0;
    background: rgba(0, 0, 0, 0.6);

    &.open {
        display: flex;
    }

    &__bg {
        position: absolute;
        z-index: 0;
        inset: 0;
    }

    &__inner-wrapper {
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        width: 90%;
        max-width: 1100px;
        margin: auto;
        padding: 14px;
        background: url('/assets/images/info-modal-bg.jpg') no-repeat center center / cover var(--color-beige);
        border-radius: 14px;

        &::before,
        &::after {
            bottom: 25px;
        }

        &::before {
            transform: rotate(-90deg);
            left: 25px;
        }

        &::after {
            transform: rotate(180deg);
            right: 25px;
        }
    }

    &__inner {
        position: relative;
        box-sizing: border-box;
        border: 1px solid var(--color-black);
        border-radius: 8px;
        padding: 75px 155px;
        text-align: center;

        &::before,
        &::after {
            top: 11px;
        }

        &::before {
            left: 11px;
        }

        &::after {
            transform: rotate(90deg);
            right: 11px;
        }
    }

    &__inner-wrapper,
    &__inner {
        &::before,
        &::after {
            position: absolute;
            display: block;
            content: '';
            width: 20px;
            height: 20px;
            background: url('/assets/images/info-modal-edges.svg') no-repeat 0 0 / cover transparent;
            pointer-events: none;
        }
    }

    &__title {
        display: block;
        max-width: 380px;
        margin-inline: auto;

        span {
            color: var(--color-mustard);

            &.lowercase-first {
                display: inline-block;
                &::first-letter {
                    text-transform: lowercase;
                }
            }
        }
    }

    &__desc {
        margin-top: 55px;
    }

    &__btn-wrapper {
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    &__close-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 75px;
        color: var(--color-black);
        text-transform: uppercase;
        font-size: math.div(14, $base-font-size) + 0rem;
        line-height: 1rem;
        letter-spacing: 1.68px;
    }
}